import React from "react";
import ComponentContentLayout from "../../hoc/ComponentContentLayout";
import { Text, Grid, Card, Container, Spacer } from "suprim-ui";
import Playground from "../../global/Playground";
const ComponentGridPage = () => {
  const grid1 = `
<Container>
    <Grid>
        <Grid.Item lg={1}><Card shadow><Card.Content></Card.Content></Card></Grid.Item>
        <Grid.Item lg={1}><Card shadow><Card.Content></Card.Content></Card></Grid.Item>
        <Grid.Item lg={1}><Card shadow> <Card.Content></Card.Content></Card></Grid.Item>
        <Grid.Item lg={1}><Card shadow><Card.Content></Card.Content></Card></Grid.Item>
        <Grid.Item lg={1}><Card shadow><Card.Content></Card.Content></Card></Grid.Item>
        <Grid.Item lg={1}><Card shadow><Card.Content></Card.Content></Card></Grid.Item>
        <Grid.Item lg={1}><Card shadow><Card.Content></Card.Content></Card></Grid.Item>
        <Grid.Item lg={1}><Card shadow><Card.Content></Card.Content></Card></Grid.Item>
        <Grid.Item lg={1}><Card shadow><Card.Content></Card.Content></Card></Grid.Item>
        <Grid.Item lg={1}><Card shadow><Card.Content></Card.Content></Card></Grid.Item>
        <Grid.Item lg={1}><Card shadow><Card.Content></Card.Content></Card></Grid.Item>
        <Grid.Item lg={1}><Card shadow><Card.Content></Card.Content></Card></Grid.Item>
    </Grid>
    <Grid>
        <Grid.Item lg={2}><Card shadow><Card.Content></Card.Content></Card></Grid.Item>
        <Grid.Item lg={2}><Card shadow><Card.Content></Card.Content></Card></Grid.Item>
        <Grid.Item lg={2}><Card shadow><Card.Content></Card.Content></Card></Grid.Item>
        <Grid.Item lg={2}><Card shadow><Card.Content></Card.Content></Card></Grid.Item>
        <Grid.Item lg={2}><Card shadow><Card.Content></Card.Content></Card></Grid.Item>
        <Grid.Item lg={2}><Card shadow><Card.Content></Card.Content></Card></Grid.Item>
    </Grid>
    <Grid>
        <Grid.Item lg={3}><Card shadow><Card.Content></Card.Content></Card></Grid.Item>
        <Grid.Item lg={3}><Card shadow><Card.Content></Card.Content></Card></Grid.Item>
        <Grid.Item lg={3}><Card shadow><Card.Content></Card.Content></Card></Grid.Item>
        <Grid.Item lg={3}><Card shadow><Card.Content></Card.Content></Card></Grid.Item>
    </Grid>
    <Grid>
        <Grid.Item lg={4}><Card shadow><Card.Content></Card.Content></Card></Grid.Item>
        <Grid.Item lg={4}><Card shadow><Card.Content></Card.Content></Card></Grid.Item>
        <Grid.Item lg={4}><Card shadow><Card.Content></Card.Content></Card></Grid.Item>
    </Grid>
    <Grid>
        <Grid.Item lg={5}><Card shadow><Card.Content></Card.Content></Card></Grid.Item>
        <Grid.Item lg={5}><Card shadow><Card.Content></Card.Content></Card></Grid.Item>
        <Grid.Item lg={2}><Card shadow><Card.Content></Card.Content></Card></Grid.Item>
    </Grid>
    <Grid>
        <Grid.Item lg={6}><Card shadow><Card.Content></Card.Content></Card></Grid.Item>
        <Grid.Item lg={6}><Card shadow><Card.Content></Card.Content></Card></Grid.Item>
    </Grid>
</Container>
    `;

  const grid2 = `
<Container>
    <Grid>
        <Grid.Item md={6} lg={3}><Card shadow><Card.Content></Card.Content></Card></Grid.Item>
        <Grid.Item md={6} lg={3}><Card shadow><Card.Content></Card.Content></Card></Grid.Item>
        <Grid.Item md={6} lg={3}><Card shadow><Card.Content></Card.Content></Card></Grid.Item>
        <Grid.Item md={6} lg={3}><Card shadow><Card.Content></Card.Content></Card></Grid.Item>
    </Grid>
    <Grid>
        <Grid.Item md={8} lg={4}><Card shadow><Card.Content></Card.Content></Card></Grid.Item>
        <Grid.Item md={4} lg={4}><Card shadow><Card.Content></Card.Content></Card></Grid.Item>
        <Grid.Item md={12} lg={4}><Card shadow><Card.Content></Card.Content></Card></Grid.Item>
    </Grid>
    <Grid>
        <Grid.Item sm={6} md={12} lg={6}><Card shadow><Card.Content></Card.Content></Card></Grid.Item>
        <Grid.Item sm={6} md={12} lg={6}><Card shadow><Card.Content></Card.Content></Card></Grid.Item>
    </Grid>
</Container>  
    `;
  const grid3 = `
    <Container>
        <Grid gutter={5}>
            <Grid.Item md={6} lg={3}><Card shadow><Card.Content></Card.Content></Card></Grid.Item>
            <Grid.Item md={6} lg={3}><Card shadow><Card.Content></Card.Content></Card></Grid.Item>
            <Grid.Item md={6} lg={3}><Card shadow><Card.Content></Card.Content></Card></Grid.Item>
            <Grid.Item md={6} lg={3}><Card shadow><Card.Content></Card.Content></Card></Grid.Item>
        </Grid>
        <Grid gutter={5}>
            <Grid.Item md={8} lg={4}><Card shadow><Card.Content></Card.Content></Card></Grid.Item>
            <Grid.Item md={4} lg={4}><Card shadow><Card.Content></Card.Content></Card></Grid.Item>
            <Grid.Item md={12} lg={4}><Card shadow><Card.Content></Card.Content></Card></Grid.Item>
        </Grid>
        <Grid gutter={5}>
            <Grid.Item sm={6} md={12} lg={6}><Card shadow><Card.Content></Card.Content></Card></Grid.Item>
            <Grid.Item sm={6} md={12} lg={6}><Card shadow><Card.Content></Card.Content></Card></Grid.Item>
        </Grid>
    </Container>  
        `;
  const grid4 = `
        <Container>
            <Grid gutter="5px">
                <Grid.Item md={6} lg={6}><Card shadow h="500px"><Card.Content></Card.Content></Card></Grid.Item>
                <Grid.Item md={6} lg={6} alignItems="center"><Card shadow><Card.Content></Card.Content></Card></Grid.Item>
            </Grid>
        </Container>  
            `;
  return (
    <ComponentContentLayout>
      <Text as="h1">Grid</Text>
      <Text as="p">Powerful fluid style layout container.</Text>
      <Text as="h3">Default</Text>
      <Text as="p" b>
        The Grid component uses dynamic CSS media query to implement responsive
        layout, It has ultra-high preformance and very small size.
      </Text>
      <Playground code={grid1} scoped={{ Container, Grid, Card }}></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">Responsive Layout</Text>
      <Text as="p">Use different layouts for different screen widths.</Text>
      <Playground code={grid2} scoped={{ Container, Grid, Card }}></Playground>
      <Text as="h3">Custom Gutter</Text>
      <Playground code={grid3} scoped={{ Container, Grid, Card }}></Playground>
      <Text as="h3">Align Content</Text>
      <Playground code={grid4} scoped={{ Container, Grid, Card }}></Playground>
    </ComponentContentLayout>
  );
};

export default ComponentGridPage;
