import React from "react";
import MainLayout from "./MainLayout";
import { Container, Grid } from "suprim-ui";
import { NavLink } from "react-router-dom";
const HomeContentLayout = ({ children }) => {
  return (
    <MainLayout>
      <section className="site-app">
        <Container>
          <Grid>
            <Grid.Item lg={2} className="component-sidebar">
              <section className="site-sidebar">
                <h3 className="site-sidebar--title">GETTING-STARTED</h3>
                <ul className="site-sidebar--lists">
                  <li>
                    <NavLink to="/guide/introduction">Introduction</NavLink>
                  </li>
                  <li>
                    <NavLink to="/guide/installation">Installation</NavLink>
                  </li>
                </ul>
                <h3 className="site-sidebar--title">Style Guide</h3>
                <ul className="site-sidebar--lists">
                  <li>
                    <NavLink to="/guide/colors">Colors</NavLink>
                  </li>
                  {/* <li>
                    <NavLink to="/guide/customization">Customization</NavLink>
                  </li> */}
                </ul>
              </section>
            </Grid.Item>
            <Grid.Item lg={8}>
              <section className="site-main">{children}</section>
            </Grid.Item>
          </Grid>
        </Container>
      </section>
    </MainLayout>
  );
};

export default HomeContentLayout;
