import React from "react";
import ComponentContentLayout from "../../hoc/ComponentContentLayout";
import { Text, Spacer, Spinner } from "suprim-ui";
import Playground from "../../global/Playground";
const ComponentSpinnerPage = () => {
  const spinner1 = `
    <Spinner></Spinner>
    `;
  const spinner2 = `
    <Spinner type="circle"></Spinner>
    `;
  const spinner3 = `
    <Spinner type="circle-one"></Spinner>
    `;
  const spinner4 = `
<>
    <Spinner size="small" type="circle-one"></Spinner>
    <Spinner size="mid" type="circle-one"></Spinner>
    <Spinner size="large" type="circle-one"></Spinner>
</>
    `;
  return (
    <ComponentContentLayout>
      <Text as="h1">Spinner</Text>
      <Text as="p">Indicate an action running in the background.</Text>
      <Spacer y="2"></Spacer>
      <Text as="h3">Default</Text>
      <Playground code={spinner1} scoped={{ Spinner }}></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">Circular</Text>
      <Playground code={spinner2} scoped={{ Spinner }}></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">Circular One</Text>
      <Playground code={spinner3} scoped={{ Spinner }}></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">Size</Text>
      <Playground code={spinner4} scoped={{ Spinner }}></Playground>
    </ComponentContentLayout>
  );
};

export default ComponentSpinnerPage;
