import React from "react";
import ComponentContentLayout from "../../hoc/ComponentContentLayout";
import { Text, Input, Button, Spacer } from "suprim-ui";
import Playground from "../../global/Playground";

const ComponentInputPage = () => {
  const input1 = `
  ()=>{
      return(
        <>
        <Input type="text" placeholder="Full Name" label="Full Name"  />
        <Input type="email" placeholder="Email" label="Email Address"   />
        <Input type="password" placeholder="Password" label="Password" passwordtoggle />
        <Button type="submit" variation="primary">Submit</Button>
        </>
      )
  }
  `;
  const input2 = `
  ()=>{
      return(
        <>
        <Input type="file" 
        htmlFor='photo'
        label='Choose File'
        trailing='Browse'
        iconBg='#ddd'   />
        <Input type="text" leading="Email" placeholder="jhon@gmail.com"/>
        <Input type="text" trailing="Password" placeholder="Full Password"/>
        </>
      )
  }
  `;
  const input3 = `
  ()=>{
      return(
        <>
        <Input type="text" label="First Name" placeholder="jhon@gmail.com" error={true}
        errorMessage='First Name is required'/>
        </>
      )
  }
  `;
  return (
    <ComponentContentLayout>
      <Text as="h1">Input</Text>
      <Text as="p">Retrieve text input from a user.</Text>
      <Playground code={input1} scoped={{ Input, Button }}></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h2">Prefix and Suffix</Text>
      <Playground code={input2} scoped={{ Input }}></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h2">Error</Text>
      <Playground code={input3} scoped={{ Input }}></Playground>
    </ComponentContentLayout>
  );
};

export default ComponentInputPage;
