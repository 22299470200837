import React from "react";
import SiteHeader from "../Layouts/SiteHeader";
import { Text, Spacer, Container } from "suprim-ui";

const MainLayout = ({ children }) => {
  return (
    <React.Fragment>
      <SiteHeader></SiteHeader>
      {children}
      <footer className="site-footer">
        <Container>
          <Spacer></Spacer>
          <Text as="p" size="small">
            &copy; Developed and All Rights Reserved By{" "}
            <a
              href="http://suprimgolay.com.np"
              target="_blank"
              rel="noopener noreferrer"
            >
              Suprim Golay.
            </a>
          </Text>
        </Container>
        <Spacer></Spacer>
      </footer>
    </React.Fragment>
  );
};

export default MainLayout;
