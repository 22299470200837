import React from "react";
import { Box, Spacer, Text } from "suprim-ui";
// import Playground from "../../global/Playground";
import ComponentContentLayout from "../../hoc/ComponentContentLayout";

const UseCopyClipBoardPage = () => {
  const code = `
import { useCallback } from 'react'

export function useCopyClipBoard() {
  const fallbackCopyTextToClipboard = useCallback((text) => {
    var textArea = document.createElement('textarea')
    textArea.value = text
    // Avoid scrolling to bottom
    textArea.style.top = '0'
    textArea.style.left = '0'
    textArea.style.position = 'fixed'

    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()

    try {
      var successful = document.execCommand('copy')
      var msg = successful ? 'successful' : 'unsuccessful'
      console.log('Fallback: Copying text command was ' + msg)
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err)
    }

    document.body.removeChild(textArea)
  }, [])
  const copyTextToClipboard = useCallback(
    (text) => {
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text)
        return
      }
      navigator.clipboard.writeText(text).then(
        function () {
          // console.log('Async: Copying to clipboard was successful!');
        },
        function (err) {
          console.error(err)
          // console.error('Async: Could not copy text: ', err);
        }
      )
    },
    [fallbackCopyTextToClipboard]
  )
  return [copyTextToClipboard]
}

    `;
  return (
    <ComponentContentLayout>
      <Text as="h1">useCopyClipBoard</Text>
      <Text as="p">Copys text to Clipboard.</Text>

      <Spacer y="2"></Spacer>
      <Box p="1rem" bg="#f1f1f1" css={{ fontSize: "1rem" }}>
        <pre>{code}</pre>
      </Box>
    </ComponentContentLayout>
  );
};

export default UseCopyClipBoardPage;
