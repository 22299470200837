import React from "react";
import { SuprimThemeProvider, theme } from "suprim-ui";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import HomePage from "./pages/HomePage/HomePage";
// COMPONENTS
import ComponentsPage from "./pages/ComponentsPage/ComponentsPage";
import CustomizationPage from "./pages/CustomizationPage/CustomizationPage";
import HomeInstallationPage from "./pages/HomePage/HomeInstallationPage";
import ComponentTextPage from "./pages/ComponentsPage/ComponentTextPage";
import ComponentButtonPage from "./pages/ComponentsPage/ComponentButtonPage";
import ComponentCodePage from "./pages/ComponentsPage/ComponentCodePage";
import ComponentContainerPage from "./pages/ComponentsPage/ComponentContainerPage";
import ComponentGridPage from "./pages/ComponentsPage/ComponentGridPage";
import ComponentCardPage from "./pages/ComponentsPage/ComponentCardPage";
import ComponentSpacerPage from "./pages/ComponentsPage/ComponentSpacerPage";
import ComponentAccordionPage from "./pages/ComponentsPage/ComponentAccordionPage";
import ComponentTabsPage from "./pages/ComponentsPage/ComponentTabsPage";
import ComponentProgressPage from "./pages/ComponentsPage/ComponentProgressPage";
import ComponentSpinnerPage from "./pages/ComponentsPage/ComponentSpinnerPage";
import ComponentNotePage from "./pages/ComponentsPage/ComponentNotePage";
import ComponentToastPage from "./pages/ComponentsPage/ComponentToastPage";
import { useSelector } from "react-redux";
import ComponentTagPage from "./pages/ComponentsPage/ComponentTagPage";
import ComponentUserPage from "./pages/ComponentsPage/ComponentUserPage";
import ComponentAvatarPage from "./pages/ComponentsPage/ComponentAvatarPage";
import ComponentTooltipPage from "./pages/ComponentsPage/ComponentTooltipPage";
import ComponentIconsPage from "./pages/ComponentsPage/ComponentIconsPage";
import ColorPage from "./pages/HomePage/ColorPage";
import ComponentSnippetPage from "./pages/ComponentsPage/ComponentSnippetPage";
import ComoponentModalPage from "./pages/ComponentsPage/ComoponentModalPage";
import ComponentInputPage from "./pages/ComponentsPage/ComponentInputPage";
import ComponentNavBarPage from "./pages/ComponentsPage/ComponentNavBarPage";
import ComponentDividerPage from "./pages/ComponentsPage/ComponentDividerPage";
import ComponentBoxPage from "./pages/ComponentsPage/ComponentBoxPage";
import ComponentDotPage from "./pages/ComponentsPage/ComponentDotPage";
import ComopnentImgPage from "./pages/ComponentsPage/ComopnentImgPage";
import ComponentDropDownPage from "./pages/ComponentsPage/ComponentDropDownPage";
import ComponentDrawerPage from "./pages/ComponentsPage/ComponentDrawerPage";
import UseLockBodyScrollPage from "./pages/UtilsPage/UseLockBodyScrollPage";
import UseCopyClipBoardPage from "./pages/UtilsPage/UseCopyClipBoardPage";
import UseClickAwayPage from "./pages/UtilsPage/UseClickAwayPage";
import UseStickyPage from "./pages/UtilsPage/UseStickyPage";
import useTogglePage from "./pages/UtilsPage/useTogglePage";
import UseWindowResizePage from "./pages/UtilsPage/UseWindowResizePage";
import ScrollRestorationPage from "./pages/UtilsPage/ScrollRestorationPage";
import ScrollRestore from "./hoc/ScrollRestore";
import ComponentPaginate from "./pages/ComponentsPage/ComponentPaginate";
function App() {
  const { dark } = useSelector((state) => state.ui);

  React.useEffect(() => {
    localStorage.setItem("dark", JSON.stringify(dark));
    if (dark) {
      document.body.classList.add("darkmode");
    } else {
      document.body.classList.remove("darkmode");
    }
  }, [dark]);

  return (
    <SuprimThemeProvider theme={dark ? theme.dark : theme.default}>
      <Router>
        <ScrollRestore />
        <Switch>
          <Route exact path="/" component={HomePage}></Route>
          <Route exact path="/guide/introduction" component={HomePage}></Route>
          <Route
            path="/guide/installation"
            component={HomeInstallationPage}
          ></Route>
          <Route path="/guide/colors" component={ColorPage}></Route>
          <Route exact path="/components" component={ComponentsPage}></Route>
          <Route path="/components/text" component={ComponentTextPage}></Route>
          <Route path="/components/img" component={ComopnentImgPage}></Route>
          <Route
            path="/components/uselockbodyscroll"
            component={UseLockBodyScrollPage}
          ></Route>
          <Route
            path="/components/usecopyclipBoard"
            component={UseCopyClipBoardPage}
          ></Route>
          <Route
            path="/components/useclickaway"
            component={UseClickAwayPage}
          ></Route>
          <Route path="/components/usesticky" component={UseStickyPage}></Route>
          <Route path="/components/usetoggle" component={useTogglePage}></Route>
          <Route
            path="/components/scrollrestoration"
            component={ScrollRestorationPage}
          ></Route>
          <Route
            path="/components/usewindowresize"
            component={UseWindowResizePage}
          ></Route>
          <Route
            path="/components/paginate"
            component={ComponentPaginate}
          ></Route>
          <Route
            path="/components/dropdown"
            component={ComponentDropDownPage}
          ></Route>
          <Route
            path="/components/drawer"
            component={ComponentDrawerPage}
          ></Route>
          <Route
            path="/components/input"
            component={ComponentInputPage}
          ></Route>
          <Route
            path="/components/navbar"
            component={ComponentNavBarPage}
          ></Route>
          <Route
            path="/components/icons"
            component={ComponentIconsPage}
          ></Route>
          <Route
            path="/components/button"
            component={ComponentButtonPage}
          ></Route>
          <Route path="/components/code" component={ComponentCodePage}></Route>
          <Route
            path="/components/container"
            component={ComponentContainerPage}
          ></Route>
          <Route path="/components/card" component={ComponentCardPage}></Route>
          <Route path="/components/grid" component={ComponentGridPage}></Route>
          <Route
            path="/components/spacer"
            component={ComponentSpacerPage}
          ></Route>
          <Route
            path="/components/divider"
            component={ComponentDividerPage}
          ></Route>
          <Route path="/components/box" component={ComponentBoxPage}></Route>
          <Route
            path="/components/accordion"
            component={ComponentAccordionPage}
          ></Route>
          <Route path="/components/tabs" component={ComponentTabsPage}></Route>
          <Route
            path="/components/spinner"
            component={ComponentSpinnerPage}
          ></Route>
          <Route
            path="/components/snippet"
            component={ComponentSnippetPage}
          ></Route>
          <Route
            path="/components/progress"
            component={ComponentProgressPage}
          ></Route>
          <Route path="/components/note" component={ComponentNotePage}></Route>
          <Route
            path="/components/toast"
            component={ComponentToastPage}
          ></Route>
          <Route
            path="/components/modal"
            component={ComoponentModalPage}
          ></Route>
          <Route path="/customization" component={CustomizationPage}></Route>
          <Route
            path="/components/avatar"
            component={ComponentAvatarPage}
          ></Route>
          <Route
            path="/components/tooltip"
            component={ComponentTooltipPage}
          ></Route>
          <Route path="/components/user" component={ComponentUserPage}></Route>
          <Route path="/components/tag" component={ComponentTagPage}></Route>
          <Route path="/components/dot" component={ComponentDotPage}></Route>
        </Switch>
      </Router>
    </SuprimThemeProvider>
  );
}

export default App;
