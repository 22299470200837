import React from "react";
import ComponentContentLayout from "../../hoc/ComponentContentLayout";
import { Text, Dot, Spacer } from "suprim-ui";
import Playground from "../../global/Playground";

const ComponentDotPage = () => {
  const dot1 = `<Dot></Dot>`;
  const dot2 = `
  ()=>{
      return(
          <>
          <Dot.Groups>
            <Dot type='default' />
            <Dot type='secondary' />
            <Dot type='primary' />
            <Dot type='warning' />
            <Dot type='error' />
          </Dot.Groups>
          </>
      )
  }
  `;
  const dot3 = `
  ()=>{
      return(
          <>
          <Dot.Groups>
            <Dot>Cancled</Dot>
            <Dot type='primary'>Ready</Dot>
            <Dot type='warning'>Warning</Dot>
            <Dot type='error'>Error</Dot>
          </Dot.Groups>
          </>
      )
  }
  `;
  return (
    <ComponentContentLayout>
      <Text as="h1">Dot</Text>
      <Text as="h3">Default</Text>
      <Playground code={dot1} scoped={{ Dot }}></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">Types</Text>
      <Playground code={dot2} scoped={{ Dot }}></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">Labled</Text>
      <Playground code={dot3} scoped={{ Dot }}></Playground>
      <Spacer y="2"></Spacer>
    </ComponentContentLayout>
  );
};

export default ComponentDotPage;
