import React from "react";
import { Box, Spacer, Text } from "suprim-ui";
import ComponentContentLayout from "../../hoc/ComponentContentLayout";

const useTogglePage = () => {
  const code = `
  import React from 'react'
  export function useToggle(initialValue = false) {
    const [value, setValue] = React.useState(initialValue)
    const toggle = React.useCallback(() => {
      setValue((v) => !v)
    }, [])
    return [value, toggle]
  }
  `;
  return (
    <ComponentContentLayout>
      <Text as="h1"> useToggle </Text>
      <Text as="p">Handle Toggle Element.</Text>
      <Spacer y="2"></Spacer>
      <Box p="1rem" bg="#f1f1f1" css={{ fontSize: "1rem" }}>
        <pre>{code}</pre>
      </Box>
    </ComponentContentLayout>
  );
};

export default useTogglePage;
