import React from "react";
import ComponentContentLayout from "../../hoc/ComponentContentLayout";
import { Text, Spacer, Container } from "suprim-ui";
import Playground from "../../global/Playground";
const ComponentSpacerPage = () => {
  const spacer1 = `
<>
    <Container style={{ background: "#ddd",display:"flex" }}>
        <Spacer y={2} ></Spacer>
    </Container>
    <Spacer y={1}></Spacer>
    <Container style={{ background: "#ddd",display:"flex"}}>
        <Spacer y={3}></Spacer>
    </Container> 
</>  
    `;
  const spacer2 = `
<Container style={{ display: 'flex' }}>
    <Container style={{ background: "#ddd" }}>
        <Spacer x={2} y={2}></Spacer>
    </Container>
    <Spacer x={2}></Spacer>
    <Container style={{ background: "#ddd" }}>
        <Spacer x={3} y={2}></Spacer>
    </Container>
</Container>
    `;
  return (
    <ComponentContentLayout>
      <Text as="h1">Spacer</Text>
      <Text as="p">Provide empty space.</Text>
      <Spacer y="2"></Spacer>
      <Text as="h3">Vertical</Text>
      <Playground code={spacer1} scoped={{ Container, Spacer }}></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">Horizontal</Text>
      <Playground code={spacer2} scoped={{ Container, Spacer }}></Playground>
    </ComponentContentLayout>
  );
};

export default ComponentSpacerPage;
