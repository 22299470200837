import React from "react";
import { Button, Container } from "suprim-ui";
import { useDispatch, useSelector } from "react-redux";
import { toggleTheme } from "../store/actions/themeAction";
import CustomRoute from "../hoc/CustomRoute";
import packageJson from "../../package.json";

const SiteHeader = () => {
  const dispatch = useDispatch();
  const { dark } = useSelector((state) => state.ui);
  console.log(packageJson.version);
  return (
    <header className="suprim-site-header">
      <nav className="suprim-site--nav">
        <Container>
          <span className="suprim-site--nav--logo">
            {dark ? (
              <img
                src="/img/logo_dark.jpg"
                alt=" Suprim React Ui"
                className="site-logo"
              ></img>
            ) : (
              <img
                src="/img/logo_white.jpg"
                alt=" Suprim React Ui"
                className="site-logo"
              ></img>
            )}
          </span>
          <ul className="suprim-site--nav--links right">
            <li>
              <a
                href="https://github.com/suprim12/suprim-ui"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span>Github</span>
              </a>
            </li>
            <li>
              <Button
                onClick={() => dispatch(toggleTheme())}
                size="small"
                icon={
                  dark ? (
                    <svg fill="currentColor" viewBox="0 0 20 20">
                      <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"></path>
                    </svg>
                  ) : (
                    <svg fill="currentColor" viewBox="0 0 20 20">
                      <path
                        fillRule="evenodd"
                        d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  )
                }
              >
                {dark ? "Dark" : "Light"}
              </Button>
            </li>
          </ul>
        </Container>
      </nav>
      <nav className="suprim-site--nav nav-line">
        <Container>
          <ul className="suprim-site--nav--links">
            <li>
              <CustomRoute to="/guide/introduction" match="/guide/:component">
                <span>Guide</span>
              </CustomRoute>
            </li>
            <li>
              <CustomRoute to="/components/text" match="/components/:component">
                <span>Components</span>
              </CustomRoute>
            </li>
          </ul>
        </Container>
      </nav>
    </header>
  );
};

export default SiteHeader;
