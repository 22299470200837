import React from "react";
import ComponentContentLayout from "../../hoc/ComponentContentLayout";
import { Text, Tabs, Snippet, Spacer, Card, useModal, Modal } from "suprim-ui";
import { useState } from "react";
import {
  Activity,
  AirPlay,
  AlertCircle,
  AlertOctagon,
  AlertTriangle,
  AlignCenter,
  AlignJustify,
  AlignLeft,
  AlignRight,
  Anchor,
  Aperture,
  Archive,
  ArrowDown,
  ArrowDownCircle,
  ArrowDownLeft,
  ArrowDownRight,
  ArrowLeft,
  ArrowLeftCircle,
  ArrowRight,
  ArrowRightCircle,
  ArrowUp,
  ArrowUpCircle,
  ArrowUpLeft,
  ArrowUpRight,
  AtSign,
  Award,
  BarChart,
  BarChart2,
  Battery,
  BatteryCharging,
  Bell,
  BellOff,
  Bluetooth,
  Bold,
  Book,
  Bookmark,
  BookOpen,
  Box,
  Briefcase,
  Calendar,
  Camera,
  CameraOff,
  Cast,
  Check,
  CheckCircle,
  CheckSquare,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  ChevronsUp,
  ChevronsDown,
  ChevronsLeft,
  ChevronsRight,
  Chrome,
  Circle,
  Clipboard,
  Clock,
  Cloud,
  CloudDrizzle,
  CloudLightning,
  CloudOff,
  CloudRain,
  CloudSnow,
  Codepen,
  Codesandbox,
  Coffee,
  Columns,
  Command,
  Compass,
  Copy,
  CornerDownLeft,
  CornerDownRight,
  CornerUpLeft,
  CornerUpRight,
  Cpu,
  CreditCard,
  Crop,
  Crosshair,
  Database,
  Download,
  DownloadCloud,
  Delete,
  Disc,
  DivideCircle,
  DivideSquare,
  Divide,
  DollarSign,
  Dribbble,
  Droplet,
  Edit,
  Edit2,
  Edit3,
  ExternalLink,
  EyeOff,
  Facebook,
  FastForward,
  Feather,
  Figma,
  File,
  FileMinus,
  FilePlus,
  FileText,
  Flag,
  Film,
  Filter,
  Folder,
  FolderMinus,
  FolderPlus,
  Framer,
  Frown,
  Gift,
  GitBranch,
  GitCommit,
  GitHub,
  Gitlab,
  GitPullRequest,
  Globe,
  Grid,
  GitMerge,
  HardDrive,
  Hash,
  Headphones,
  Heart,
  HelpCircle,
  Hexagon,
  Home,
  Info,
  Inbox,
  Instagram,
  Italic,
  Key,
  Layers,
  Layout,
  LifeBuoy,
  Link,
  Link2,
  Linkedin,
  List,
  Loader,
  Lock,
  LogIn,
  LogOut,
  Mail,
  Map,
  MapPin,
  Maximize,
  Maximize2,
  Meh,
  Menu,
  MessageCircle,
  MessageSquare,
  MicOff,
  Minimize,
  Minimize2,
  Minus,
  MinusCircle,
  MinusSquare,
  Monitor,
  Moon,
  MoreHorizontal,
  MoreVertical,
  Move,
  Music,
  Navigation,
  Navigation2,
  Octagon,
  MousePointer,
  Package,
  Paperclip,
  Pause,
  PauseCircle,
  PenTool,
  Percent,
  Phone,
  PhoneCall,
  PhoneForwarded,
  PhoneIncoming,
  PhoneMissed,
  PhoneOff,
  PhoneOutgoing,
  PieChart,
  Play,
  PlayCircle,
  PlusCircle,
  PlusSquare,
  Plus,
  Power,
  Printer,
  Pocket,
  Radio,
  RefreshCcw,
  RefreshCw,
  Rss,
  RotateCcw,
  RotateCw,
  Repeat,
  Rewind,
  Save,
  Scissors,
  Search,
  Server,
  Settings,
  Send,
  Share,
  Share2,
  Shield,
  ShieldOff,
  ShoppingBag,
  ShoppingCart,
  Shuffle,
  Sidebar,
  SkipBack,
  SkipForward,
  Slack,
  Slash,
  Sliders,
  Smartphone,
  Smile,
  Speaker,
  Square,
  Star,
  StopCircle,
  Sun,
  Sunrise,
  Sunset,
  Tablet,
  Tag,
  Target,
  Terminal,
  Thermometer,
  ThumbsDown,
  ThumbsUp,
  ToggleLeft,
  ToggleRight,
  Tool,
  Trash,
  Trash2,
  Trello,
  TrendingDown,
  TrendingUp,
  Triangle,
  Truck,
  Tv,
  Twitch,
  Type,
  Twitter,
  Umbrella,
  Underline,
  Unlock,
  Upload,
  UploadCloud,
  User,
  UserCheck,
  UserPlus,
  UserMinus,
  Users,
  UserX,
  Video,
  VideoOff,
  Voicemail,
  Volume,
  Volume1,
  Volume2,
  VolumeX,
  Watch,
  Wifi,
  WifiOff,
  Wind,
  X,
  XCircle,
  XOctagon,
  XSquare,
  Youtube,
  Zap,
  ZapOff,
  ZoomIn,
  ZoomOut,
} from "suprim-react-icons";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const ComponentIconsPage = () => {
  const { dark } = useSelector((state) => state.ui);
  let iconslistArr = [
    Activity,
    AirPlay,
    AlertCircle,
    AlertOctagon,
    AlertTriangle,
    AlignCenter,
    AlignJustify,
    AlignLeft,
    AlignRight,
    Anchor,
    Aperture,
    Archive,
    ArrowDown,
    ArrowDownCircle,
    ArrowDownLeft,
    ArrowDownRight,
    ArrowLeft,
    ArrowLeftCircle,
    ArrowRight,
    ArrowRightCircle,
    ArrowUp,
    ArrowUpCircle,
    ArrowUpLeft,
    ArrowUpRight,
    AtSign,
    Award,
    BarChart,
    BarChart2,
    Battery,
    BatteryCharging,
    Bell,
    BellOff,
    Bluetooth,
    Bold,
    Book,
    Bookmark,
    BookOpen,
    Box,
    Briefcase,
    Calendar,
    Camera,
    CameraOff,
    Cast,
    Check,
    CheckCircle,
    CheckSquare,
    ChevronDown,
    ChevronLeft,
    ChevronRight,
    ChevronUp,
    ChevronsUp,
    ChevronsDown,
    ChevronsLeft,
    ChevronsRight,
    Chrome,
    Circle,
    Clipboard,
    Clock,
    Cloud,
    CloudDrizzle,
    CloudLightning,
    CloudOff,
    CloudRain,
    CloudSnow,
    Codepen,
    Codesandbox,
    Coffee,
    Columns,
    Command,
    Compass,
    Copy,
    CornerDownLeft,
    CornerDownRight,
    CornerUpLeft,
    CornerUpRight,
    Cpu,
    CreditCard,
    Crop,
    Crosshair,
    Database,
    Download,
    DownloadCloud,
    Delete,
    Disc,
    DivideCircle,
    DivideSquare,
    Divide,
    DollarSign,
    Dribbble,
    Droplet,
    Edit,
    Edit2,
    Edit3,
    ExternalLink,
    EyeOff,
    Facebook,
    FastForward,
    Feather,
    Figma,
    File,
    FileMinus,
    FilePlus,
    FileText,
    Flag,
    Film,
    Filter,
    Folder,
    FolderMinus,
    FolderPlus,
    Framer,
    Frown,
    Gift,
    GitBranch,
    GitCommit,
    GitHub,
    Gitlab,
    GitPullRequest,
    Globe,
    Grid,
    GitMerge,
    HardDrive,
    Hash,
    Headphones,
    Heart,
    HelpCircle,
    Hexagon,
    Home,
    Info,
    Inbox,
    Instagram,
    Italic,
    Key,
    Layers,
    Layout,
    LifeBuoy,
    Link,
    Link2,
    Linkedin,
    List,
    Loader,
    Lock,
    LogIn,
    LogOut,
    Mail,
    Map,
    MapPin,
    Maximize,
    Maximize2,
    Meh,
    Menu,
    MessageCircle,
    MessageSquare,
    MicOff,
    Minimize,
    Minimize2,
    Minus,
    MinusCircle,
    MinusSquare,
    Monitor,
    Moon,
    MoreHorizontal,
    MoreVertical,
    Move,
    Music,
    Navigation,
    Navigation2,
    Octagon,
    MousePointer,
    Package,
    Paperclip,
    Pause,
    PauseCircle,
    PenTool,
    Percent,
    Phone,
    PhoneCall,
    PhoneForwarded,
    PhoneIncoming,
    PhoneMissed,
    PhoneOff,
    PhoneOutgoing,
    PieChart,
    Play,
    PlayCircle,
    PlusCircle,
    PlusSquare,
    Plus,
    Power,
    Printer,
    Pocket,
    Radio,
    RefreshCcw,
    RefreshCw,
    Rss,
    RotateCcw,
    RotateCw,
    Repeat,
    Rewind,
    Save,
    Scissors,
    Search,
    Server,
    Settings,
    Send,
    Share,
    Share2,
    Shield,
    ShieldOff,
    ShoppingBag,
    ShoppingCart,
    Shuffle,
    Sidebar,
    SkipBack,
    SkipForward,
    Slack,
    Slash,
    Sliders,
    Smartphone,
    Smile,
    Speaker,
    Square,
    Star,
    StopCircle,
    Sun,
    Sunrise,
    Sunset,
    Tablet,
    Tag,
    Target,
    Terminal,
    Thermometer,
    ThumbsDown,
    ThumbsUp,
    ToggleLeft,
    ToggleRight,
    Tool,
    Trash,
    Trash2,
    Trello,
    TrendingDown,
    TrendingUp,
    Triangle,
    Truck,
    Tv,
    Twitch,
    Type,
    Twitter,
    Umbrella,
    Underline,
    Unlock,
    Upload,
    UploadCloud,
    User,
    UserCheck,
    UserPlus,
    UserMinus,
    Users,
    UserX,
    Video,
    VideoOff,
    Voicemail,
    Volume,
    Volume1,
    Volume2,
    VolumeX,
    Watch,
    Wifi,
    WifiOff,
    Wind,
    X,
    XCircle,
    XOctagon,
    XSquare,
    Youtube,
    Zap,
    ZapOff,
    ZoomIn,
    ZoomOut,
  ];
  const [iconslist] = useState(iconslistArr);
  const [filteredicons, setFilteredIcons] = useState([]);
  const [searchtext, setsearchtext] = useState("");

  const handlesearchtxtChange = (e) => {
    setsearchtext(e.target.value);
    if (e.target.value.length !== 0) {
      setFilteredIcons(
        iconslist.filter((item) => {
          const regex = new RegExp(`^${searchtext}`, "gi");
          return item.displayName.match(regex);
        })
      );
    } else {
      setFilteredIcons(iconslistArr);
    }
  };
  useEffect(() => {
    setFilteredIcons(iconslist);
  }, [setFilteredIcons, iconslist]);

  return (
    <ComponentContentLayout>
      <Text as="h1">Icons</Text>
      <Text as="p">Display semantic vector graphics in SVG format.</Text>
      <Spacer y={2}></Spacer>
      <Text as="h3">Installation</Text>
      <Text as="p" b>
        Install the package in your project directory.
      </Text>
      <Spacer y={1}></Spacer>
      <Tabs>
        <Tabs.Item label="npm">
          <Snippet text="npm i suprim-react-icons"></Snippet>
        </Tabs.Item>
        <Tabs.Item label="Yarn">
          <Snippet text="yarn i suprim-react-icons"></Snippet>
        </Tabs.Item>
      </Tabs>
      <Spacer y={2}></Spacer>
      <Text as="h3">Icons Gallery</Text>
      <section className="icons-gallery">
        <div className={`icons-gallery--search ${dark ? "dark" : ""}`}>
          <input
            type="text"
            value={searchtext}
            onChange={handlesearchtxtChange}
            placeholder="Search Icons"
          ></input>
        </div>
        <div className="icons-gallery-content">
          {filteredicons &&
            filteredicons.map((Item, index) => (
              <IconItem key={index} Item={Item}></IconItem>
            ))}
        </div>
      </section>
    </ComponentContentLayout>
  );
};

const IconItem = ({ Item }) => {
  const TagName = Item;
  const [iconmodal, togglemodal] = useModal();
  return (
    <>
      <Modal {...iconmodal}>
        <Modal.Content>
          <Snippet
            text={`import {${Item.displayName}} from  "suprim-react-icons"`}
          ></Snippet>
          <Spacer y="1"></Spacer>
          <Snippet text={`<${Item.displayName}/>`}></Snippet>
        </Modal.Content>
      </Modal>
      <Card className="icons-gallery--item" hoverable onClick={togglemodal}>
        <Card.Content className="icons-gallery--item-content">
          <TagName />
          <Text as="p">{`${Item.displayName}`}</Text>
        </Card.Content>
      </Card>
    </>
  );
};

export default ComponentIconsPage;
