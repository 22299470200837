import React from "react";
import ComponentContentLayout from "../../hoc/ComponentContentLayout";
import { Text, Spacer, DropDown } from "suprim-ui";
import Playground from "../../global/Playground";

const ComponentDropDownPage = () => {
  const dropdown1 = `
    () => {
      return(
      <DropDown title="Dropdown">
        <DropDown.Item>File 1</DropDown.Item>
        <DropDown.Item>File 2</DropDown.Item>
        <DropDown.Divider />
        <DropDown.Item>File 1</DropDown.Item>
        <DropDown.Item>File 2</DropDown.Item>
      </DropDown>
      )
    }
  `;
  return (
    <ComponentContentLayout>
      <Text as="h1">DropDown</Text>
      <Text as="p">Display related but alternate actions for a button.</Text>
      <Spacer y="2"></Spacer>
      <Text as="h3">Default</Text>
      <Spacer y="2"></Spacer>
      <Playground code={dropdown1} scoped={{ DropDown }}></Playground>
    </ComponentContentLayout>
  );
};

export default ComponentDropDownPage;
