import React from "react";
import ComponentContentLayout from "../../hoc/ComponentContentLayout";
import { Text, Spacer } from "suprim-ui";
import Playground from "../../global/Playground";
const ComponentTextPage = () => {
  const textcode1 = `
() => {
    const text = 'The evil rabbit jumps over the fence.'
    return (
        <>
            <Text as="h1">{text}</Text>
            <Text as="h2">{text}</Text>
            <Text as="h3">{text}</Text>
            <Text as="h4">{text}</Text>
            <Text as="h5">{text}</Text>
            <Text as="h6">{text}</Text>
        </>
    )
}
`;
  const textcode2 = `
() => {
    const text = 'Our mission is to make cloud computing accessible to everyone. We build products for developers and designers. And those who aspire to become one.';
    return (
        <>
        <Text as="p">{text}</Text>
        <Text as="p" b>{text}</Text>
        </>
    )
}
`;
  const textcode3 = `
() => {
    const text = 'Our mission is to make cloud computing accessible to everyone. We build products for developers and designers. And those who aspire to become one.';
    return (
        <>
        <Text as="blockquote">{text}</Text>
        <Text as="blockquote" b>{text}</Text>
        </>
    )
}
`;
  const textcode4 = `
() => {
    const text = 'Our mission is to make cloud computing accessible to everyone.'
    return (
        <>
        <Text as="p" type="primary">{text}</Text>
        <Text as="p" type="error">{text}</Text>
        <Text as="p" type="success">{text}</Text>
        <Text as="p" type="warning">{text}</Text>
        </>
    )
}
`;
  const textcode5 = `
() => {
    const text = 'The evil rabbit jumps over the fence.';
    return (
        <>
            <Text as="h1" sm={{fontSize:"2rem"}} md={{fontSize:"3rem"}}>{text}</Text>
            <Text as="h2" sm={{fontSize:"1.875rem"}} md={{fontSize:"2rem"}}>{text}</Text>
            <Text as="h3" sm={{fontSize:"1.5rem"}} md={{fontSize:"1rem"}}>{text}</Text>
            <Text as="h4" color="purple">{text}</Text>
            <Text as="h5" align="center">{text}</Text>
            <Text as="h6" bg="yellow" align="center" color="#000">{text}</Text>
        </>
    )
}
`;
  return (
    <ComponentContentLayout>
      <Text as="h1">Text</Text>
      <Text as="p">Display text using well-defined typographic styles.</Text>
      <Spacer y={2}></Spacer>
      <Text as="h3">Headings</Text>
      <Text as="p">Use Text to standardize text content.</Text>
      <Playground code={textcode1} scoped={{ Text }}></Playground>
      <Spacer y={2}></Spacer>
      <Text as="h3">Paragraph</Text>
      <Text as="p">Add as "p" and b attributes to a Text.</Text>
      <Playground code={textcode2} scoped={{ Text }}></Playground>
      <Spacer y={2}></Spacer>
      <Text as="h3">Blockquote</Text>
      <Text as="p">Add as "blockquote" and b attributes to a Text.</Text>
      <Playground code={textcode3} scoped={{ Text }}></Playground>
      <Spacer y={2}></Spacer>
      <Text as="h3">Types</Text>
      <Text as="p">
        Add type "success | primary | warning | error " and b attributes to a
        Text.
      </Text>
      <Playground code={textcode4} scoped={{ Text }}></Playground>
      <Text as="h3">Responsive & Align</Text>
      <Text as="p">Add responsive font size and text align</Text>
      <Playground code={textcode5} scoped={{ Text }}></Playground>
    </ComponentContentLayout>
  );
};

export default ComponentTextPage;
