import React from "react";
import { Box, Spacer, Text } from "suprim-ui";
import ComponentContentLayout from "../../hoc/ComponentContentLayout";

const UseStickyPage = () => {
  const code = `
  import { useEffect, useState, useRef } from 'react'

  function useSticky() {
    const [isSticky, setSticky] = useState(false)
    const element = useRef(null)
  
    const handleScroll = () => {
      window.scrollY > element.current.getBoundingClientRect().bottom
        ? setSticky(true)
        : setSticky(false)
    }
    // This function handle the scroll performance issue
    const debounce = (func, wait = 20, immediate = true) => {
      let timeOut
      return () => {
        const context = this
        const args = arguments
        const later = () => {
          timeOut = null
          if (!immediate) func.apply(context, args)
        }
        const callNow = immediate && !timeOut
        clearTimeout(timeOut)
        timeOut = setTimeout(later, wait)
        if (callNow) func.apply(context, args)
      }
    }
  
    useEffect(() => {
      window.addEventListener('scroll', debounce(handleScroll))
      return () => {
        window.removeEventListener('scroll', () => handleScroll)
      }
    }, [debounce, handleScroll])
  
    return [isSticky, element]
  }
  
  export default useSticky
  
        `;
  return (
    <ComponentContentLayout>
      <Text as="h1">useSticky</Text>
      <Text as="p">Handle Sticky Element.</Text>
      <Spacer y="2"></Spacer>
      <Box p="1rem" bg="#f1f1f1" css={{ fontSize: "1rem" }}>
        <pre>{code}</pre>
      </Box>
    </ComponentContentLayout>
  );
};

export default UseStickyPage;
