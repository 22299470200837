import React from "react";
import { Button, Drawer, Spacer, Text, useDrawer } from "suprim-ui";
import Playground from "../../global/Playground";
import ComponentContentLayout from "../../hoc/ComponentContentLayout";

const ComponentDrawerPage = () => {
  const drawer1 = `
    ()=>{
      const [drawer1, toggleDrawer1] = useDrawer();
      const [drawer2, toggleDrawer2] = useDrawer();
      return(
        <>
            <Button.Group>
                <Button type="button" onClick={toggleDrawer1}>
                Open Drawer
                </Button>
                <Button type="button" onClick={toggleDrawer2}>
                Open Drawer 2
                </Button>
            </Button.Group>
            <Drawer {...drawer1}>
                <h1>Drawer</h1>
            </Drawer>
            <Drawer {...drawer2}>
              <h1>Drawer Two</h1>
            </Drawer>
        </>
      )
  }
  `;

  const drawer2 = `
  () => {
    const [drawer1, toggleDrawer1] = useDrawer();
    const  [placement,setPlacement] =  React.useState('');
    const handleclick = (placement) =>{
        toggleDrawer1();
        setPlacement(placement);
    }
    return(
      <>
        <Button.Group>
            <Button type="button"  onClick={()=> handleclick('top')}>
            Open Top Drawer
            </Button>
            <Button type="button"  onClick={()=> handleclick('bottom')}>
            Open Bottom Drawer
            </Button>
            <Button type="button" onClick={()=> handleclick('left')}>
            Open Left Drawer
            </Button>
            <Button type="button"  onClick={()=> handleclick('right')}>
            Open Right Drawer
            </Button>
        </Button.Group>
        <Drawer placement={placement} {...drawer1}>
            <h1>Drawer</h1>
        </Drawer>
      </>
    )
}
`;

  const drawer3 = `
()=>{
  const [drawer1, toggleDrawer1] = useDrawer();
  return(
    <>
    <Button type="button" onClick={toggleDrawer1}>
        Open Drawer
    </Button>
    <Drawer w="80vw" {...drawer1}>
        <h1>Drawer</h1>
    </Drawer>
    </>
  )
}
`;
  return (
    <ComponentContentLayout>
      <Text as="h1">Drawer</Text>
      <Text as="p">A panel which slides in from the edge of the screen.</Text>
      <Spacer y="2"></Spacer>
      <Text as="h3">Default</Text>
      <Playground
        code={drawer1}
        scoped={{ Button, Drawer, useDrawer }}
      ></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">Placement</Text>
      <Playground
        code={drawer2}
        scoped={{ Button, Drawer, useDrawer }}
      ></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">Custom Width</Text>
      <Playground
        code={drawer3}
        scoped={{ Button, Drawer, useDrawer }}
      ></Playground>
      <Text as="p">
        More Features Comming this component soon. Such as Drawer.Header,
        Drawer.Text, Drawer.List etc
      </Text>
    </ComponentContentLayout>
  );
};

export default ComponentDrawerPage;
