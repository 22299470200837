import React from "react";
import ComponentContentLayout from "../../hoc/ComponentContentLayout";
import { Text, Spacer, Tabs } from "suprim-ui";
import Playground from "../../global/Playground";
const ComponentTabsPage = () => {
  const tab1 = `
    <>
    <Tabs>
        <Tabs.Item label="Tab 1">
            Tab 1 Content
        </Tabs.Item>
        <Tabs.Item label="Tab 2">
            Tab 2 Content
        </Tabs.Item>
    </Tabs>
    </>
        `;
  const tab2 = `
    <Tabs>
        <Tabs.Item label="Tab 1">
            Tab 1 Content
        </Tabs.Item>
        <Tabs.Item label="Tab 2" disabled>
            Tab 2 Content
        </Tabs.Item>
    </Tabs>
            `;
  return (
    <ComponentContentLayout>
      <Text as="h1">Tabs</Text>
      <Text as="p">
        A Tab is a hidden section of content activated by a Menu.
      </Text>
      <Spacer y="2"></Spacer>
      <Text as="h3">Default</Text>
      <Text as="p">Toggle display of different templates.</Text>
      <Playground code={tab1} scoped={{ Tabs }}></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">Disabled</Text>
      <Text as="p">Toggle display of different templates.</Text>
      <Playground code={tab2} scoped={{ Tabs }}></Playground>
    </ComponentContentLayout>
  );
};

export default ComponentTabsPage;
