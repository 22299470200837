import React from "react";
import ComponentContentLayout from "../../hoc/ComponentContentLayout";
import { Text, User, Spacer } from "suprim-ui";
import Playground from "../../global/Playground";

const ComponentUserPage = () => {
  const user1 = `
  <>
  <User src='https://lh3.googleusercontent.com/ogw/ADGmqu_Sxit7llxZ59-C4b7N8875ou86D2MIXlyYb7ii=s83-c-mo' sameSite name='Suprim'>
   
  </User>
  </>
  `;
  const user2 = `
  <>
  <User src='https://lh3.googleusercontent.com/ogw/ADGmqu_Sxit7llxZ59-C4b7N8875ou86D2MIXlyYb7ii=s83-c-mo' sameSite name='Suprim'>
   Javascript Developer
  </User>
  </>
  `;

  const user3 = `
  <>
  <User src='https://lh3.googleusercontent.com/ogw/ADGmqu_Sxit7llxZ59-C4b7N8875ou86D2MIXlyYb7ii=s83-c-mo' sameSite name="Suprim">
    <User.Link href="asdas">@asdd</User.Link>
  </User>
  </>
  `;

  return (
    <ComponentContentLayout>
      <Text as="h1">User</Text>
      <Text as="p">Display user profile or social information.</Text>
      <Spacer y="3"></Spacer>
      <Text as="h3">Default</Text>
      <Text as="p">Show username.</Text>
      <Playground code={user1} scoped={{ User }}></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">Description</Text>
      <Text as="p">Show an extra description.</Text>
      <Playground code={user2} scoped={{ User }}></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">Social</Text>
      <Text as="p">Show link below user name.</Text>
      <Playground code={user3} scoped={{ User }}></Playground>
      <Spacer y="2"></Spacer>
    </ComponentContentLayout>
  );
};

export default ComponentUserPage;
