import React from 'react'
import MainLayout from '../../hoc/MainLayout'

const CustomizationPage = () => {
    return (
        <MainLayout>
            Customization
        </MainLayout>
    )
}

export default CustomizationPage
