import React from "react";
import { Text, Spacer, Grid } from "suprim-ui";
import HomeContentLayout from "../../hoc/HomeContentLayout";
import { useSelector } from "react-redux";

const ColorPage = (props) => {
  const { dark } = useSelector((state) => state.ui);
  const colorsArr = [
    {
      name: "Background",
      light: "#FFF",
      dark: "#000",
    },
    {
      name: "Accent 1",
      light: "#FAFAFA",
      dark: "#111",
    },
    {
      name: "Accent 2",
      light: "#EAEAEA",
      dark: "#333",
    },
    {
      name: "Accent 3",
      light: "#999",
      dark: "#444",
    },
    {
      name: "Accent 4",
      light: "#888",
      dark: "#666",
    },
    {
      name: "Accent 5",
      light: "#666",
      dark: "#888",
    },
    {
      name: "Accent 6",
      light: "#444",
      dark: "#999",
    },
    {
      name: "Accent 7",
      light: "#333",
      dark: "#EAEAEA",
    },
    {
      name: "Accent 8",
      light: "#111",
      dark: "#FAFAFA",
    },
    {
      name: "Foreground",
      light: "#000",
      dark: "#FFF",
    },
    {
      name: "Primary",
      light: "#0ab",
      dark: "#0ab",
    },
  ];
  const successColorArr = [
    {
      name: "Lighter",
      light: "#D3E5FF",
      dark: "#D3E5FF",
    },
    {
      name: "Light",
      light: "#3291FF",
      dark: "#3291FF",
    },
    {
      name: "Default",
      light: "#0070F3",
      dark: "#0070F3",
    },
    {
      name: "Dark",
      light: "#0761D1",
      dark: "#0761D1",
    },
  ];
  const errorColorArr = [
    {
      name: "Lighter",
      light: "#F7D4D6",
      dark: "#F7D4D6",
    },
    {
      name: "Light",
      light: "#FF1A1A",
      dark: "#FF1A1A",
    },
    {
      name: "Default",
      light: "#E00",
      dark: "#E00",
    },
    {
      name: "Dark",
      light: "#C50000",
      dark: "#C50000",
    },
  ];
  const warningColorArr = [
    {
      name: "Lighter",
      light: "#FFEFCF",
      dark: "#FFEFCF",
    },
    {
      name: "Light",
      light: "#F7B955",
      dark: "#F7B955",
    },
    {
      name: "Default",
      light: "#F5A623",
      dark: "#F5A623",
    },
    {
      name: "Dark",
      light: "#AB570A",
      dark: "#AB570A",
    },
  ];
  return (
    <HomeContentLayout>
      <Text as="h2">Color</Text>
      <Text as="p">Default colors for themes.</Text>
      <Spacer y="2"></Spacer>
      <Text as="h3">Primary</Text>
      <div className="colors-cards">
        {colorsArr.map((item, index) => (
          <div
            className={`colors-card ${index > 3 ? "invert" : ""} ${
              dark ? "dark" : ""
            }`}
            key={index}
            style={{ backgroundColor: `${!dark ? item.light : item.dark}` }}
          >
            <Grid>
              <Grid.Item lg={6}>
                <Text as="h3">{item.name}</Text>
              </Grid.Item>
              <Grid.Item lg={6}>
                <Text as="p">{!dark ? item.light : item.dark}</Text>
              </Grid.Item>
            </Grid>
          </div>
        ))}
      </div>
      <Spacer y="2"></Spacer>
      <Text as="h3">Status</Text>
      <Text as="p">
        Use different colors to express more emotional changes.
      </Text>
      <Spacer y="1"></Spacer>
      <Text as="h4">Success</Text>
      <Spacer y="1"></Spacer>
      <div className="colors-cards">
        {successColorArr.map((item, index) => (
          <div
            className={`colors-card ${index > 0 ? "invert" : ""} ${
              dark ? "dark-invert" : ""
            }`}
            key={index}
            style={{ backgroundColor: `${!dark ? item.light : item.dark}` }}
          >
            <Grid>
              <Grid.Item lg={6}>
                <Text as="h3">{item.name}</Text>
              </Grid.Item>
              <Grid.Item lg={6}>
                <Text as="p">{!dark ? item.light : item.dark}</Text>
              </Grid.Item>
            </Grid>
          </div>
        ))}
      </div>
      <Spacer y="2"></Spacer>
      <Text as="h4">Error</Text>
      <div className="colors-cards">
        {errorColorArr.map((item, index) => (
          <div
            className={`colors-card ${index > 0 ? "invert" : ""} ${
              dark ? "dark-invert" : ""
            }`}
            key={index}
            style={{ backgroundColor: `${!dark ? item.light : item.dark}` }}
          >
            <Grid>
              <Grid.Item lg={6}>
                <Text as="h3">{item.name}</Text>
              </Grid.Item>
              <Grid.Item lg={6}>
                <Text as="p">{!dark ? item.light : item.dark}</Text>
              </Grid.Item>
            </Grid>
          </div>
        ))}
      </div>
      <Spacer y="2"></Spacer>
      <Text as="h4">Warning</Text>
      <Spacer y="1"></Spacer>
      <div className="colors-cards">
        {warningColorArr.map((item, index) => (
          <div
            className={`colors-card  ${index > 0 ? "invert" : ""} ${
              dark ? "dark-invert" : ""
            }`}
            key={index}
            style={{ backgroundColor: `${!dark ? item.light : item.dark}` }}
          >
            <Grid>
              <Grid.Item lg={6}>
                <Text as="h3">{item.name}</Text>
              </Grid.Item>
              <Grid.Item lg={6}>
                <Text as="p">{!dark ? item.light : item.dark}</Text>
              </Grid.Item>
            </Grid>
          </div>
        ))}
      </div>
    </HomeContentLayout>
  );
};

export default ColorPage;
