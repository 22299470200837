import React from "react";
import ComponentContentLayout from "../../hoc/ComponentContentLayout";
import { Text, Divider, Spacer } from "suprim-ui";
import Playground from "../../global/Playground";

const ComponentDividerPage = () => {
  const divider1 = `
()=>{
    return(
        <>
        <Text as="p">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
        </Text>
        <Divider></Divider>
        <Text as="p">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
        </Text>
        </>
    )
}
  `;
  const divider2 = `
  ()=>{
      return(
          <>
          <Text as="p">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
          </Text>
          <Divider>Text</Divider>
          <Text as="p">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
          </Text>
          <Divider orientation="left">Left</Divider>
          <Text as="p">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
          </Text>
          <Divider orientation="right">Right</Divider>
          </>
      )
  }
    `;
  const divider3 = `
    ()=>{
        return(
    <>
        <Text as="p">
        Facebook
        <Divider type="vertical"></Divider>
        Twitter
        </Text>
    </>
        )
    }
      `;
  return (
    <ComponentContentLayout>
      <Text as="h1">Divider</Text>
      <Text as="p">A divider line separates different content.</Text>
      <Text as="h3">Default</Text>
      <Playground code={divider1} scoped={{ Divider, Text }}></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">Divider With Text</Text>
      <Playground code={divider2} scoped={{ Divider, Text }}></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">Vertical Divider</Text>
      <Playground code={divider3} scoped={{ Divider, Text }}></Playground>
    </ComponentContentLayout>
  );
};

export default ComponentDividerPage;
