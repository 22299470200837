import React from "react";
import ComponentContentLayout from "../../hoc/ComponentContentLayout";
import { Text, Spacer, Button, useToasts } from "suprim-ui";
import Playground from "../../global/Playground";
const ComponentToastPage = () => {
  const toast1 = `
()=>{
    const toast = useToasts();
    const showtoast = (type = "default") => {
        toast.add({ content: 'The Evil Rabbit jumped over the fence.', type })
    }
    return(
        <Button onClick={showtoast} >Show Toast</Button>
    )
}
    `;
  const toast2 = `
 ()=>{
    const toast = useToasts();
    const showtoastMulti = () => {
        toast.add({ content: 'The Evil Rabbit jumped over the fence. The Evil Rabbit jumped over the fence. The Evil Rabbit jumped over the fence. The Evil Rabbit jumped over the fence.' })
    }
     return(
        <Button onClick={showtoastMulti} >Show Toast</Button>
     )
 }   
    `;
  const toast3 = `
()=>{
    const toast = useToasts();
    const showtoast = (type = "default") => {
        toast.add({ content: 'The Evil Rabbit jumped over the fence.', type })
    }
    return(
        <>
            <Button  variation="primary" size="fluid" onClick={() => showtoast("primary")} >Show Toast Primary</Button>
           
            <Button variation="success" size="fluid" onClick={() => showtoast("success")} >Show Toast Success</Button>
           
            <Button variation="error" size="fluid" onClick={() => showtoast("danger")} >Show Toast Error</Button>
           
            <Button variation="warning" size="fluid" onClick={() => showtoast("warning")} >Show Toast Warning</Button>
           
            <Button variation="default" size="fluid" onClick={() => showtoast("secondary")} >Show Toast Secondary</Button>
        </>
    )
}
        `;
  return (
    <ComponentContentLayout>
      <Text as="h1">Toast</Text>
      <Text as="p">Display an important message globally.</Text>
      <Spacer y="2"></Spacer>
      <Text as="h3">Default</Text>
      <Text as="p">Basic usage.</Text>
      <Playground code={toast1} scoped={{ Button, useToasts }}></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">Multiline</Text>
      <Text as="p">Display multiline or overlong text.</Text>
      <Playground code={toast2} scoped={{ Button, useToasts }}></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">Type</Text>
      <Playground
        className="grid"
        code={toast3}
        scoped={{ Button, useToasts }}
      ></Playground>
    </ComponentContentLayout>
  );
};

export default ComponentToastPage;
