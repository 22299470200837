import React from "react";
import { Box, Button, Spacer, Text, useLockBodyScroll } from "suprim-ui";
import Playground from "../../global/Playground";
import ComponentContentLayout from "../../hoc/ComponentContentLayout";

const UseLockBodyScrollPage = () => {
  const [lock, setLock] = React.useState(false);

  const lock1 = `
  ()=>{
        useLockBodyScroll(lock);
        const handleclick = () => {
        setLock(lock => (!lock)); //   const [lock, setLock] = React.useState(false);
        };
      return(
        <Button onClick={handleclick}>Toggle Lock Scroll</Button>
      )
  }
  `;

  const lockcode = `
import { useLayoutEffect } from 'react' 

export function useLockBodyScroll(toggle) {
useLayoutEffect(() => {
    document.body.style.overflow = toggle ? 'hidden' : 'visible'
    document.body.style.marginRight = toggle ? 16 + 'px' : 0
    return () => {
    document.body.style.overflow = 'visible'
    }
}, [toggle])
}
  `;
  return (
    <ComponentContentLayout>
      <Text as="h1">useLockBodyScroll</Text>
      <Text as="p">
        Looks screent prevents from scrolling usefull in various cases, such as
        opening Drawer, Model, Popup
      </Text>
      <Playground
        code={lock1}
        scoped={{ Button, useLockBodyScroll, setLock, lock }}
      ></Playground>
      <Spacer y="2"></Spacer>
      <Box p="1rem" bg="#f1f1f1" css={{ fontSize: "1rem" }}>
        <pre>{lockcode}</pre>
      </Box>
    </ComponentContentLayout>
  );
};

export default UseLockBodyScrollPage;
