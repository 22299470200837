import React from "react";
import { Box, Spacer, Text } from "suprim-ui";
import ComponentContentLayout from "../../hoc/ComponentContentLayout";

const ScrollRestorationPage = () => {
  const code = `
    import { useEffect } from "react";
    import { useLocation } from "react-router-dom";
    
    export default function ScrollRestoration() {
        const { pathname } = useLocation();
    
        useEffect(() => {
        window.scrollTo(0, 0);
        }, [pathname]);
    
        return null;
    }
    `;
  return (
    <ComponentContentLayout>
      <Text as="h1"> ScrollRestoration </Text>
      <Text as="p">
        Handle Scroll Restoration on using package react-router-dom.
      </Text>
      <Spacer y="2"></Spacer>
      <Box p="1rem" bg="#f1f1f1" css={{ fontSize: "1rem" }}>
        <pre>{code}</pre>
      </Box>
    </ComponentContentLayout>
  );
};

export default ScrollRestorationPage;
