import React from "react";
import { Text, Spacer, ToolTip, Button } from "suprim-ui";
import ComponentContentLayout from "../../hoc/ComponentContentLayout";
import Playground from "../../global/Playground";

const ComponentTooltipPage = () => {
  const tooltip1 = `
    <ToolTip label="Simple Tooltip as Render">Tooltip Hover</ToolTip>
    `;
  const tooltip2 = `
    <ToolTip label="Simple Tooltip as Render" trigger="click">Tooltip Click</ToolTip>
    `;
  const tooltip3 = `
    <ToolTip label={
        <div>
        <Text>asdasd</Text>
        <Button>Hey</Button>
        </div>}>
        <Button>Click Me</Button>
    </ToolTip>
    `;
  const tooltip4 = `
  <>
   <ToolTip placement="top" label="Simple Tooltip as Render" >Tooltip </ToolTip>
   <Spacer x="2"></Spacer>
   <ToolTip placement="bottom" label="Simple Tooltip as Render" >Bottom Tooltip </ToolTip>
  </>
    `;
  const tooltip5 = `
    <>
     <ToolTip type="default" label="Simple Tooltip as Render" >Tooltip </ToolTip>
     <Spacer x="2"></Spacer>
     <ToolTip type="primary"  label="Simple Tooltip as Render" > Tooltip </ToolTip>
     <Spacer x="2"></Spacer>
     <ToolTip type="success"  label="Simple Tooltip as Render" > Tooltip </ToolTip>
     <Spacer x="2"></Spacer>
     <ToolTip type="secondary"  label="Simple Tooltip as Render" > Tooltip </ToolTip>
     <Spacer x="2"></Spacer>
     <ToolTip type="warning"  label="Simple Tooltip as Render" > Tooltip </ToolTip>
     <Spacer x="2"></Spacer>
     <ToolTip type="error"  label="Simple Tooltip as Render" > Tooltip </ToolTip>
    </>
      `;
  const tooltip6 = `
      <>
       <ToolTip placement="top" label="Simple Tooltip as Render" >Tooltip </ToolTip>
       <ToolTip placement="topstart" label="Simple Tooltip as Render" >Tooltip </ToolTip>   
       <ToolTip placement="topend" label="Simple Tooltip as Render" >Tooltip </ToolTip>
       <Spacer x="2"></Spacer>
       <ToolTip placement="bottom" label="Simple Tooltip as Render" >Bottom Tooltip </ToolTip>
       <ToolTip placement="bottomstart" label="Simple Tooltip as Render" >Bottom Start Tooltip </ToolTip>
       <ToolTip placement="bottomend" label="Simple Tooltip as Render" >Bottom  End Tooltip </ToolTip>
       <Spacer x="2"></Spacer>
       <ToolTip placement="left" label="Simple Tooltip as Render" >Left Tooltip </ToolTip>
       <ToolTip placement="right" label="Simple Tooltip as Render" >Right Tooltip </ToolTip>
      </>
        `;
  return (
    <ComponentContentLayout>
      <Text as="h1">Tooltip</Text>
      <Text as="p">Displays additional information on hover.</Text>
      <Spacer y="3"></Spacer>
      <Text as="h3">Default</Text>
      <Text as="p">Basic usage.</Text>
      <Playground code={tooltip1} scoped={{ ToolTip }}></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">Trigger</Text>
      <Text as="p">Trigger by click.</Text>
      <Playground code={tooltip2} scoped={{ ToolTip }}></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">With Components</Text>
      <Text as="p">Combined with different components.</Text>
      <Playground
        code={tooltip3}
        scoped={{ ToolTip, Button, Text }}
      ></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">Custom Content</Text>
      <Text as="p">You can customize the pop-up content.</Text>
      <Playground code={tooltip4} scoped={{ ToolTip, Spacer }}></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">Types</Text>
      <Text as="p">Different state.</Text>
      <Playground code={tooltip5} scoped={{ ToolTip, Spacer }}></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">Placements</Text>
      <Text as="p">Different state.</Text>
      <Playground code={tooltip6} scoped={{ ToolTip, Spacer }}></Playground>
    </ComponentContentLayout>
  );
};

export default ComponentTooltipPage;
