import React from "react";
import { Text, Note, Modal, useModal, Button, Spacer } from "suprim-ui";
import ComponentContentLayout from "../../hoc/ComponentContentLayout";
import Playground from "../../global/Playground";
const ComoponentModalPage = () => {
  const modal1 = `
  ()=>{
    const [modal, togglemodal] = useModal();
    return(
        <>
        <Button onClick={togglemodal}>Click Here</Button>
        <Modal {...modal}>
          <Modal.Content>
            This is modal 2
            <Modal.Footer>
              <Button onClick={togglemodal} size="fluid">
                Close
              </Button>
              <Button onClick={togglemodal} size="fluid">
                Submit
              </Button>
            </Modal.Footer>
          </Modal.Content>
        </Modal>
        </>
    )
}`;
  return (
    <ComponentContentLayout>
      <Text as="h1">Modal</Text>
      <Text as="p">
        Display popup content that requires attention or provides additional
        information.
      </Text>
      <Note>Just want a text notification? Try Toast Component</Note>
      <Spacer y="2"></Spacer>
      <Text as="h2">Basic</Text>
      <Text as="p">Use open control whether Modal is displayed.</Text>
      <Playground
        scoped={{ Modal, useModal, Button }}
        code={modal1}
      ></Playground>
    </ComponentContentLayout>
  );
};

export default ComoponentModalPage;
