import React from "react";
import { Text, Avatar, Spacer } from "suprim-ui";
import ComponentContentLayout from "../../hoc/ComponentContentLayout";
import Playground from "../../global/Playground";

const ComponentAvatarPage = () => {
  const avatar1 = `
<>
    <Avatar
    size="small"
    square
    src="https://lh3.googleusercontent.com/ogw/ADGmqu_Sxit7llxZ59-C4b7N8875ou86D2MIXlyYb7ii=s83-c-mo"
    ></Avatar>
    <Avatar   size="small" src="https://lh3.googleusercontent.com/ogw/ADGmqu_Sxit7llxZ59-C4b7N8875ou86D2MIXlyYb7ii=s83-c-mo"></Avatar>
</>
    `;
  const avatar2 = `
<>
<Avatar
size="mini"
src="https://lh3.googleusercontent.com/ogw/ADGmqu_Sxit7llxZ59-C4b7N8875ou86D2MIXlyYb7ii=s83-c-mo"
></Avatar>
<Avatar
size="small"
src="https://lh3.googleusercontent.com/ogw/ADGmqu_Sxit7llxZ59-C4b7N8875ou86D2MIXlyYb7ii=s83-c-mo"
></Avatar>
<Avatar
size="medium"
src="https://lh3.googleusercontent.com/ogw/ADGmqu_Sxit7llxZ59-C4b7N8875ou86D2MIXlyYb7ii=s83-c-mo"
></Avatar>{" "}
<Avatar
size="large"
src="https://lh3.googleusercontent.com/ogw/ADGmqu_Sxit7llxZ59-C4b7N8875ou86D2MIXlyYb7ii=s83-c-mo"
></Avatar>
</>
`;
  const avatar3 = `
<>
<Avatar text="W" size="small"></Avatar>
<Avatar text="A" size="small"></Avatar>
<Avatar text="K" size="small"></Avatar>
<Avatar text="Jhon" size="small"></Avatar>
</>
`;

  const avatar4 = `
<>
<Avatar.Group>
<Avatar size="small" src="https://lh3.googleusercontent.com/ogw/ADGmqu_Sxit7llxZ59-C4b7N8875ou86D2MIXlyYb7ii=s83-c-mo"></Avatar>
<Avatar size="small" src="https://lh3.googleusercontent.com/ogw/ADGmqu_Sxit7llxZ59-C4b7N8875ou86D2MIXlyYb7ii=s83-c-mo"></Avatar>
<Avatar size="small" src="https://lh3.googleusercontent.com/ogw/ADGmqu_Sxit7llxZ59-C4b7N8875ou86D2MIXlyYb7ii=s83-c-mo"></Avatar>
<Avatar size="small" src="https://lh3.googleusercontent.com/ogw/ADGmqu_Sxit7llxZ59-C4b7N8875ou86D2MIXlyYb7ii=s83-c-mo"></Avatar>
</Avatar.Group>

<Avatar.Group count="12">
<Avatar size="small" src="https://lh3.googleusercontent.com/ogw/ADGmqu_Sxit7llxZ59-C4b7N8875ou86D2MIXlyYb7ii=s83-c-mo"></Avatar>
<Avatar size="small" src="https://lh3.googleusercontent.com/ogw/ADGmqu_Sxit7llxZ59-C4b7N8875ou86D2MIXlyYb7ii=s83-c-mo"></Avatar>
<Avatar size="small" src="https://lh3.googleusercontent.com/ogw/ADGmqu_Sxit7llxZ59-C4b7N8875ou86D2MIXlyYb7ii=s83-c-mo"></Avatar>
</Avatar.Group>
</>


`;

  return (
    <ComponentContentLayout>
      <Text as="h1">Avatar</Text>
      <Text as="p">
        Avatars represent a user or a team. Stacked avatars represent a group of
        people.
      </Text>
      <Spacer y={2}></Spacer>
      <Text as="h3">Default</Text>
      <Text as="p">The Avatar contains Circle and Square.</Text>
      <Playground code={avatar1} scoped={{ Avatar }}></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">Sizes</Text>
      <Text as="p">You can specify different sizes of Avatar.</Text>
      <Playground code={avatar2} scoped={{ Avatar }}></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">Text</Text>
      <Text as="p">Display text in the avatar box.</Text>
      <Playground code={avatar3} scoped={{ Avatar }}></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">Group</Text>
      <Text as="p">Multiple avatars can overlap and stack together.</Text>
      <Playground code={avatar4} scoped={{ Avatar }}></Playground>
    </ComponentContentLayout>
  );
};

export default ComponentAvatarPage;
