import React from "react";
import ComponentContentLayout from "../../hoc/ComponentContentLayout";
import { Text, Spacer, Pagination } from "suprim-ui";
import Playground from "../../global/Playground";

const ComponentPaginate = () => {
  const page1 = `
  <Pagination prev={"Prev"} next={"Next"} count={10} limit={5}></Pagination>
    `;

  const page2 = `
  <>
    <Pagination prev={"Prev"} next={"Next"} count={10} limit={5} size="mini"></Pagination>
    <Spacer y={1}></Spacer>
    <Pagination prev={"Prev"} next={"Next"} count={10} limit={5} size="small"></Pagination>
    <Spacer y={1}></Spacer>
    <Pagination prev={"Prev"} next={"Next"} count={10} limit={5} size="medium"></Pagination>
    <Spacer y={1}></Spacer>
    <Pagination prev={"Prev"} next={"Next"} count={10} limit={5} size="large"></Pagination>
   </>
    `;
  return (
    <ComponentContentLayout>
      <Text as="h1">Pagination</Text>
      <Text as="p">
        A long list can be divided into several pages using Pagination, and only
        one page will be loaded at a time.
      </Text>
      <Spacer y={2}></Spacer>
      <Text as="h3">Default</Text>
      <Text as="p">
        If you want to browse the data by navigating through pages.
      </Text>
      <Playground code={page1} scoped={{ Pagination }}></Playground>
      <Spacer y={2}></Spacer>
      <Text as="h3">Sizes</Text>
      <Playground code={page2} scoped={{ Pagination, Spacer }}></Playground>
      {/* <Playground code={note1} scoped={{ Note }}></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">Type</Text>
      <Playground code={note2} scoped={{ Note, Spacer }}></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">Custom Label</Text>
      <Playground code={note3} scoped={{ Note }}></Playground> */}
    </ComponentContentLayout>
  );
};

export default ComponentPaginate;
