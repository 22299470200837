import * as types from "../types";

const initialState = {
  dark: JSON.parse(localStorage.getItem("dark")) || false,
};
export const themeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_THEME:
      return {
        ...state,
        dark: !state.dark,
      };
    default:
      return state;
  }
};
