import React from "react";
import ComponentContentLayout from "../../hoc/ComponentContentLayout";
import { Text, Container, Spacer } from "suprim-ui";
import Playground from "../../global/Playground";

const ComponentContainerPage = () => {
  const container1 = `
    <Container>Container</Container>
    `;
  const container2 = `
    <Container fluid>Container Fluid</Container>
    `;
  return (
    <ComponentContentLayout>
      <Text as="h1">Container</Text>
      <Text as="p">Used to wrap an content..</Text>
      <Text as="h3">Default</Text>
      <Text as="p">The basic Container.</Text>
      <Playground code={container1} scoped={{ Container }}></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">Fluid</Text>
      <Text as="p">The basic Container Fluid.</Text>
      <Playground code={container2} scoped={{ Container }}></Playground>
    </ComponentContentLayout>
  );
};

export default ComponentContainerPage;
