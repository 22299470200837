import React from "react";
import HomeContentLayout from "../../hoc/HomeContentLayout";
import { Text, Spacer, Button } from "suprim-ui";
import Playground from "../../global/Playground";

const HomeInstallationPage = () => {
  const code1 = `
   () => <Button>Click Me</Button> `;
  const code2 = `import {Button} from 'suprim-ui'`;
  return (
    <HomeContentLayout>
      <Text as="h2">Installation</Text>
      <Text as="h3">Setup</Text>
      <Text as="p">
        Ensure your have the latest version of NodeJS, and a package manager:
        NPM or Yarn. If you want to see a complete example, please browser our
        sample collection.
      </Text>
      <Spacer y="2"></Spacer>
      <Text as="h3">Single component</Text>
      <Text as="p">E.g.</Text>
      <Text as="p">Step 1: {code2}.</Text>
      <div>
        <Playground scoped={{ Button }} code={code1}></Playground>
      </div>
      <Spacer y={2}></Spacer>
      <Text as="h3">Create React App</Text>
      <Text as="p">
        A React project created by create-react-app can also easily use
        suprim-react-ui. You don't need to make any changes, just install and
        use.
      </Text>
      <Spacer></Spacer>
      <a href="https://github.com/suprim12/getting-started-react-app">
        https://github.com/suprim12/getting-started-react-app
      </a>
      <Spacer y={2}></Spacer>
      <Text as="h3">Create Next App</Text>
      <Text as="p">
        A NextJs project created by create-next-app can also easily use
        suprim-ui. You don't need to make any changes, just install and use.
      </Text>
      <a href="https://github.com/suprim12/getting-started-next-app">
        https://github.com/suprim12/getting-started-next-app
      </a>
    </HomeContentLayout>
  );
};

export default HomeInstallationPage;
