import React from "react";
import ComponentContentLayout from "../../hoc/ComponentContentLayout";
import { Text, Note, Spacer } from "suprim-ui";
import Playground from "../../global/Playground";
const ComponentNotePage = () => {
  const note1 = `
    <Note>This note details something important.</Note>
    `;
  const note2 = `
<>
    <Note type="primary" >This note details something important.</Note>
    <Spacer y="1"></Spacer>
    <Note type="success" >This note details something important.</Note>
    <Spacer y="1"></Spacer>
    <Note type="danger" >This note details something important.</Note>
    <Spacer y="1"></Spacer>
    <Note type="warning" >This note details something important.</Note>
    <Spacer y="1"></Spacer>
    <Note type="secondary" >This note details something important.</Note>
</>
    `;
  const note3 = `
<Note label="Custom">This note details something important.</Note>
`;
  return (
    <ComponentContentLayout>
      <Text as="h1">Note</Text>
      <Text as="p">
        Display text that requires attention or provides additional information.
      </Text>
      <Spacer y="2"></Spacer>
      <Text as="h3">Default</Text>
      <Text as="p">Show a piece of text or components.</Text>
      <Playground code={note1} scoped={{ Note }}></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">Type</Text>
      <Playground code={note2} scoped={{ Note, Spacer }}></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">Custom Label</Text>
      <Playground code={note3} scoped={{ Note }}></Playground>
    </ComponentContentLayout>
  );
};

export default ComponentNotePage;
