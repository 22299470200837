import React from "react";
import ComponentContentLayout from "../../hoc/ComponentContentLayout";
import Playground from "../../global/Playground";
import { Img, Spacer, Text } from "suprim-ui";

const ComopnentImgPage = () => {
  const img1 = `
    ()=>{
        return(
            <Img  src="https://images.pexels.com/photos/421129/pexels-photo-421129.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"  alt="auto"/>
        )
    }
    `;
  const img2 = `
    ()=>{
        return(
            <Img borderRadius="10px"  src="https://images.pexels.com/photos/421129/pexels-photo-421129.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"  alt="auto"/>
        )
    }
    `;
  const img3 = `
    ()=>{
        return(
            <Img width="200px" height="200px" objectFit="cover" borderRadius="10px"  src="https://images.pexels.com/photos/421129/pexels-photo-421129.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"  alt="auto"/>
        )
    }
    `;
  const img4 = `
    ()=>{
        return(
            <>
            <Img width="200px" shadow height="200px" objectFit="cover" borderRadius="10px"  src="https://images.pexels.com/photos/421129/pexels-photo-421129.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"  alt="auto"/>
            <Spacer y="2"/>
            <Img width="200px" hoverable height="200px" objectFit="cover" borderRadius="10px"  src="https://images.pexels.com/photos/421129/pexels-photo-421129.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"  alt="auto"/>
            </>
            )
    }
    `;
  return (
    <ComponentContentLayout>
      <Text as="h1">Img</Text>
      <Text as="p">Display image content.</Text>
      <Spacer y="2"></Spacer>
      <Text as="h3">Default</Text>
      <Playground code={img1} scoped={{ Img }}></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">Radius</Text>
      <Playground code={img2} scoped={{ Img }}></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">Custom Width & Height</Text>
      <Playground code={img3} scoped={{ Img }}></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">Shadow & Hoverable</Text>
      <Playground code={img4} scoped={{ Img, Spacer }}></Playground>
    </ComponentContentLayout>
  );
};

export default ComopnentImgPage;
