import React from "react";
import ComponentContentLayout from "../../hoc/ComponentContentLayout";
import { Spacer, Text, Tag } from "suprim-ui";
import Playground from "../../global/Playground";

const ComponentTagPage = () => {
  const tag1 = `
<>
    <Tag>Status: Custom</Tag>
</>
    `;
  const tag2 = `
    <>
        <Tag type="primary">Status: Custom</Tag>
        <Tag type="success">Status: Custom</Tag>
        <Tag type="warning">Status: Custom</Tag>
        <Tag type="error">Status: Custom</Tag>
        <Tag type="secondary">Status: Custom</Tag>
    </>
        `;

  const tag3 = `
        <>
            <Tag type="primary" invert>Status: Custom</Tag>
            <Tag type="success" invert>Status: Custom</Tag>
            <Tag type="warning" invert>Status: Custom</Tag>
            <Tag type="error" invert>Status: Custom</Tag>
            <Tag type="secondary" invert>Status: Custom</Tag>
        </>
            `;
  const tag4 = `
            <>
                <Tag color="purple" invert>Hello World</Tag>
            </>
                `;

  const tag5 = `
                <>
                    <Tag  size="mini" color="purple" invert>Hello</Tag>
                    <Tag  size="large" color="green" invert>World</Tag>
                </>
                    `;
  return (
    <ComponentContentLayout>
      <Text as="h1">Tag</Text>
      <Text as="p">
        Display a unique keyword, optionally as a part of a set.
      </Text>
      <Spacer y="3"></Spacer>
      <Text as="h3">Default</Text>
      <Text as="p">Basic usage.</Text>
      <Playground code={tag1} scoped={{ Tag }}></Playground>
      <Spacer y="2"></Spacer>

      <Text as="h3">Types</Text>
      <Text as="p">Show different states with colors.</Text>
      <Playground code={tag2} scoped={{ Tag }}></Playground>
      <Spacer y="2"></Spacer>

      <Text as="h3">Invert</Text>
      <Text as="p">Invert color and background.</Text>
      <Playground code={tag3} scoped={{ Tag }}></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">Custom</Text>
      <Text as="p">Invert color and background.</Text>
      <Playground code={tag4} scoped={{ Tag }}></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">Sizes</Text>
      <Playground code={tag5} scoped={{ Tag }}></Playground>
    </ComponentContentLayout>
  );
};

export default ComponentTagPage;
