import React from "react";
import ComponentContentLayout from "../../hoc/ComponentContentLayout";
import { Text, NavBar, Spacer } from "suprim-ui";
import { NavLink } from "react-router-dom";
import Playground from "../../global/Playground";

const ComponentNavBarPage = () => {
  const nav1 = `
  ()=>{
    return(
      <>
      <NavBar>
      <NavBar.Container>
        <NavBar.Menu>
          <NavBar.Link>
            {/* This is an import from react-router-dom */}
            <NavLink exact to="/components/navbar/">
              Home
            </NavLink>
          </NavBar.Link>
          <NavBar.Link>
            {/* This is an import from react-router-dom */}
            <NavLink exact to="/components/navbar/a">
              About
            </NavLink>
          </NavBar.Link>
        </NavBar.Menu>
      </NavBar.Container>
    </NavBar>
      </>
    )
  }
  `;

  const nav2 = `
  ()=>{
    return(
      <>
      <NavBar fixed>
      <NavBar.Container>
        <NavBar.Menu>
          <NavBar.Link>
            {/* This is an import from react-router-dom */}
            <NavLink exact to="/components/navbar/">
              Home
            </NavLink>
          </NavBar.Link>
          <NavBar.Link>
            {/* This is an import from react-router-dom */}
            <NavLink exact to="/components/navbar/a">
              About
            </NavLink>
          </NavBar.Link>
        </NavBar.Menu>
      </NavBar.Container>
    </NavBar>
      </>
    )
  }
  `;
  const nav3 = `
  ()=>{
    return(
      <>
      <NavBar variation="transparent noline">
      <NavBar.Container>
        <NavBar.Menu>
          <NavBar.Link>
            {/* This is an import from react-router-dom */}
            <NavLink exact to="/components/navbar/">
              Home
            </NavLink>
          </NavBar.Link>
          <NavBar.Link>
            {/* This is an import from react-router-dom */}
            <NavLink exact to="/components/navbar/a">
              About
            </NavLink>
          </NavBar.Link>
        </NavBar.Menu>
      </NavBar.Container>
    </NavBar>
      </>
    )
  }
  `;
  const nav4 = `
  ()=>{
    return(
      <>
      <NavBar variation="transparent noline">
      <NavBar.Container>
      <NavBar.Collapse
      collapse='xl'
      ham={<span >Menu</span>}
    >
    <NavBar.Logo>Logo</NavBar.Logo>
      <NavBar.Menu>
        <NavBar.Link>
          {/* This is an import from react-router-dom */}
          <NavLink exact to="/components/navbar/">
            Home
          </NavLink>
        </NavBar.Link>
        <NavBar.Link>
          {/* This is an import from react-router-dom */}
          <NavLink exact to="/components/navbar/a">
            About
          </NavLink>
        </NavBar.Link>
      </NavBar.Menu>
      </NavBar.Collapse>
      </NavBar.Container>
    </NavBar>
      </>
    )
  }
  `;
  return (
    <ComponentContentLayout>
      <Text as="h1">NavBar</Text>
      <Text as="h3">Default</Text>
      <Text as="p">For navigation at the top of the page.</Text>
      <Playground code={nav1} scoped={{ NavBar, NavLink }}></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">Fixed</Text>
      <Playground code={nav2} scoped={{ NavBar, NavLink }}></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">Variation</Text>
      <Playground code={nav3} scoped={{ NavBar, NavLink }}></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">Collapse</Text>
      <Playground code={nav4} scoped={{ NavBar, NavLink }}></Playground>
      <Spacer y="2"></Spacer>
    </ComponentContentLayout>
  );
};

export default ComponentNavBarPage;
