import React, { useEffect } from "react";
import HomeContentLayout from "../../hoc/HomeContentLayout";
import { Code, Text, Spacer, Box } from "suprim-ui";
import { useHistory, Link } from "react-router-dom";
import { useSelector } from "react-redux";

const IconComp = ({ to, text, isnew }) => {
  return (
    <Link to={to} className="icon-comp">
      {isnew && <span className="badge">New</span>}
      <Box
        padding={{
          p: "1rem ",
        }}
        d="flex"
        border={{
          borderColor: "",
          borderWidth: "1px",
          borderRadius: "5px",
        }}
        w="120px"
        h="70px"
        bg="#000"
        alignItems="center"
        justifyContent="center"
        css={{ textAlign: "center", textDecoration: "none" }}
        hoverable
      >
        <Text
          as="h5"
          color="#fff"
          margin={{ m: "0px" }}
          css={{ textTransform: "capitalize" }}
        >
          {text}
        </Text>
      </Box>
    </Link>
  );
};

const HomePage = () => {
  const history = useHistory();
  const { dark } = useSelector((state) => state.ui);
  useEffect(() => {
    history.push("/guide/introduction");
  }, [history]);
  return (
    <HomeContentLayout>
      <Text as="h2">Introduction</Text>
      {dark ? (
        <img src="/img/bg_dark.jpg" alt="card" />
      ) : (
        <img src="/img/bg_white.jpg" alt="card" />
      )}
      <Text as="h3">About</Text>
      <Text as="p">
        <Code> supirm-ui </Code> is a ui implementation for styling React,
        NextJs App originating from Suprim's design.
      </Text>
      <Text as="p">
        The design of the Suprim UI is concise and aesthetic feeling, this is an
        important reason for popular of Suprim. Now you can use them at will in
        React App.
        <br /> Suprim UI is an open-source design system for building modern
        websites and applications, all our projects are open source.
      </Text>
      <Spacer y="2"></Spacer>
      <Text as="h3">Components Overview</Text>
      <Text>
        we provides plenty of UI components to enrich your web applications, and
        we will improve components experience consistently.
      </Text>
      <Spacer y="1"></Spacer>
      <Text as="h3">General</Text>
      <ul className="comp-lists">
        <li>
          <IconComp to="/components/text" text="Text"></IconComp>
        </li>
        <li>
          <IconComp to="/components/img" text="Img"></IconComp>
        </li>
        <li>
          <IconComp to="/components/button" text="Button"></IconComp>
        </li>
        <li>
          <IconComp to="/components/code" text="Code"></IconComp>
        </li>
        <li>
          <IconComp to="/components/icons" text="icons"></IconComp>
        </li>
      </ul>
      <Spacer y="1"></Spacer>
      <Text as="h3">Data Entry</Text>
      <ul className="comp-lists">
        <li>
          <IconComp to="/components/input" text="input"></IconComp>
        </li>
      </ul>
      <Spacer y="1"></Spacer>
      <Text as="h3">Navigation</Text>
      <ul className="comp-lists">
        <li>
          <IconComp to="/components/navbar" text="NavBar"></IconComp>
        </li>
        <li>
          <IconComp to="/components/drawer" text="Drawer"></IconComp>
        </li>
        <li>
          <IconComp to="/components/dropdown" text="DropDown"></IconComp>
        </li>
        <li>
          <IconComp
            to="/components/paginate"
            text="Pagination"
            isnew
          ></IconComp>
        </li>
      </ul>
      <Spacer y="1"></Spacer>
      <Text as="h3">Layout</Text>
      <ul className="comp-lists">
        <li>
          <IconComp to="/components/container" text="Container"></IconComp>
        </li>
        <li>
          <IconComp to="/components/grid" text="Grid"></IconComp>
        </li>
        <li>
          <IconComp to="/components/spacer" text="Spacer"></IconComp>
        </li>
        <li>
          <IconComp to="/components/divider" text="Divider"></IconComp>
        </li>
      </ul>
      <Spacer y="1"></Spacer>
      <Text as="h3">Surfaces</Text>
      <ul className="comp-lists">
        <li>
          <IconComp to="/components/card" text="Card"></IconComp>
        </li>
        <li>
          <IconComp to="/components/box" text="Box"></IconComp>
        </li>
      </ul>
      <Spacer y="1"></Spacer>
      <Text as="h3">Modules</Text>
      <ul className="comp-lists">
        <li>
          <IconComp to="/components/accordion" text="Accordion"></IconComp>
        </li>
        <li>
          <IconComp to="/components/tabs" text="Tabs"></IconComp>
        </li>
      </ul>
      <Spacer y="1"></Spacer>
      <Text as="h3">Feedback</Text>
      <ul className="comp-lists">
        <li>
          <IconComp to="/components/spinner" text="Spinner"></IconComp>
        </li>
        <li>
          <IconComp to="/components/progress" text="Progress"></IconComp>
        </li>
        <li>
          <IconComp to="/components/note" text="Note"></IconComp>
        </li>
        <li>
          <IconComp to="/components/toast" text="Toast"></IconComp>
        </li>
        <li>
          <IconComp to="/components/modal" text="Modal"></IconComp>
        </li>
        <li>
          <IconComp to="/components/snippet" text="Snippet"></IconComp>
        </li>
      </ul>
      <Spacer y="1"></Spacer>
      <Text as="h3">Data Display</Text>
      <ul className="comp-lists">
        <li>
          <IconComp to="/components/avatar" text="Avatar"></IconComp>
        </li>
        <li>
          <IconComp to="/components/tooltip" text="ToolTip"></IconComp>
        </li>
        <li>
          <IconComp to="/components/user" text="User"></IconComp>
        </li>
        <li>
          <IconComp to="/components/tag" text="Tag"></IconComp>
        </li>
        <li>
          <IconComp to="/components/dot" text="Dot"></IconComp>
        </li>
      </ul>
      <Spacer y={2}></Spacer>
      <Text as="p">More Components & Features are Comming Soon...</Text>
      <Spacer y={2}></Spacer>
      <Text as="h3">License</Text>
      <Text>
        Licensed under the{" "}
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://github.com/suprim12/suprim-ui/blob/master/LICENSE"
        >
          MIT LICENSE
        </a>
      </Text>
    </HomeContentLayout>
  );
};

export default HomePage;
