import React from "react";
import ComponentContentLayout from "../../hoc/ComponentContentLayout";
import Playground from "../../global/Playground";
import { Text, Code, Spacer } from "suprim-ui";
const ComponentCodePage = () => {
  const code1 = `
    <Text>Run <Code>npm i suprim-react-ui</Code> to install.</Text>
    `;
  const code2 = `
<Code blocks>
    <Code>
        npm i suprim-react-ui
    </Code>
    <Code>
        npm i suprim-react-ui
    </Code>
</Code>
    `;

  const code3 = `
<Code blocks width="240px">
    <Code>
        npm i suprim-react-ui
    </Code>
    <Code>
        npm i suprim-react-ui
    </Code>
</Code>
`;
  return (
    <ComponentContentLayout>
      <Text as="h1">Code</Text>
      <Text as="p">Show source code in a standardized way.</Text>
      <Text as="blockquote">
        <b>Note:</b> Want to display shell code snippets? Try Snippet.
      </Text>

      <Text as="h3">Default</Text>
      <Text as="p">Basic inline codes.</Text>
      <Playground code={code1} scoped={{ Text, Code }}></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">Code Block</Text>
      <Text as="p">Multi line code display.</Text>
      <Playground code={code2} scoped={{ Code }}></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">Width</Text>
      <Text as="p">Specify width manually, or other styles.</Text>
      <Playground code={code3} scoped={{ Code }}></Playground>
    </ComponentContentLayout>
  );
};

export default ComponentCodePage;
