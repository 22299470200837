import React from "react";
import { Box, Spacer, Text } from "suprim-ui";
import ComponentContentLayout from "../../hoc/ComponentContentLayout";

const UseClickAwayPage = () => {
  const code = `
import { useEffect } from 'react'
export function useClickAway(ref, handler) {
useEffect(() => {
    const listener = (event) =>
    !ref.current || ref.current.contains(event.target) ? null : handler(event)
    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)
    return () => {
    document.removeEventListener('mousedown', listener)
    document.removeEventListener('touchstart', listener)
    }
}, [ref, handler])
}
    `;
  return (
    <ComponentContentLayout>
      <Text as="h1">useClickAway</Text>
      <Text as="p">Action on Clicking away from the target.</Text>
      <Spacer y="2"></Spacer>
      <Box p="1rem" bg="#f1f1f1" css={{ fontSize: "1rem" }}>
        <pre>{code}</pre>
      </Box>
    </ComponentContentLayout>
  );
};

export default UseClickAwayPage;
