import React, { useState, useRef, useEffect, useCallback } from "react";
import theme from "prism-react-renderer/themes/duotoneDark";
import { useToasts } from "suprim-ui";
import { LiveProvider, LiveEditor, LiveError, LivePreview } from "react-live";

const Playground = ({ className, code, scoped }) => {
  return (
    <div className={`playground ${className ? className : ""} `}>
      <LiveProvider code={code} scope={scoped} theme={theme}>
        <PlaygroundContent />
        <PlaygroundEditorContent></PlaygroundEditorContent>
      </LiveProvider>
    </div>
  );
};

const PlaygroundContent = ({ className }) => {
  return (
    <div className={`playground-content ${className ? className : ""}`}>
      <LivePreview className="live-preview" />
    </div>
  );
};
const PlaygroundEditorContent = () => {
  const toasts = useToasts();
  const [open, setopen] = useState(false);
  const codeRef = useRef(null);
  const copybtnRef = useRef(null);
  const handleopen = () => {
    setopen(!open);
  };
  const fallbackCopyTextToClipboard = useCallback((text) => {
    var textArea = document.createElement("textarea");
    textArea.value = text;
    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand("copy");
      var msg = successful ? "successful" : "unsuccessful";
      console.log("Fallback: Copying text command was " + msg);
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
    }

    document.body.removeChild(textArea);
  }, []);
  const copyTextToClipboard = useCallback(
    (text) => {
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
      }
      navigator.clipboard.writeText(text).then(
        function () {
          console.log("Async: Copying to clipboard was successful!");
        },
        function (err) {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },
    [fallbackCopyTextToClipboard]
  );

  const handlecopy = useCallback(() => {
    copyTextToClipboard(
      codeRef.current.querySelector("textarea").textContent.trim()
    );
    toasts.add({ content: "Code Copied.", type: "default" });
    // eslint-disable-next-line
  }, [copyTextToClipboard]);

  useEffect(() => {
    if (open) {
      copybtnRef.current.addEventListener("click", handlecopy);
    }
  }, [open, handlecopy]);
  return (
    <div className="playground-editor">
      <div className="playground-editor--header">
        <div
          className="playground-editor--header-item flex-start"
          onClick={handleopen}
        >
          <svg fill="currentColor" viewBox="0 0 20 20">
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
          Code Editor
        </div>
        {open && (
          <div
            className="playground-editor--header-item flex-end"
            ref={copybtnRef}
          >
            <svg fill="currentColor" viewBox="0 0 20 20">
              <path d="M8 2a1 1 0 000 2h2a1 1 0 100-2H8z"></path>
              <path d="M3 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v6h-4.586l1.293-1.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L10.414 13H15v3a2 2 0 01-2 2H5a2 2 0 01-2-2V5zM15 11h2a1 1 0 110 2h-2v-2z"></path>
            </svg>
          </div>
        )}
      </div>
      {open && (
        <div className="playground-editor--content" ref={codeRef}>
          <LiveEditor />
          <LiveError />
        </div>
      )}
    </div>
  );
};

Playground.Content = PlaygroundContent;
Playground.EditoContent = PlaygroundEditorContent;
export default Playground;
