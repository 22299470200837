import React from "react";
import ComponentContentLayout from "../../hoc/ComponentContentLayout";
import { Text, Button, Loading, Spacer, IconButton } from "suprim-ui";
import { Search } from "suprim-react-icons";
import Playground from "../../global/Playground";
const ComponentButtonPage = () => {
  const btncode1 = `
<>
    <Button>Action</Button>
    <Button>Action2</Button>
</>
`;
  const btncode2 = `
<>
    <Button><Loading></Loading></Button>
    <Button><Loading></Loading></Button>
</>
`.trim();
  const btncode3 = `
()=>{
    return       <Button disabled>Action</Button>
}
`.trim();
  const btncode4 = `
()=>{
    return       <Button shadow>Action</Button>
}
`.trim();
  const btncode5 = `
<>
    <Button variation="success">Success</Button>
    <Button variation="error">Error</Button>
    <Button variation="primary">Primary</Button>
    <Button variation="warning">Warning</Button>
</>
`.trim();
  const btncode6 = `
<>
    <Button variation="success" ghost>Success</Button>
    <Button variation="error" ghost>Error</Button>
    <Button variation="primary" ghost>Primary</Button>
    <Button variation="warning" ghost>Warning</Button>
</>
`.trim();
  const btncode7 = `
()=>{
    return(
<>
<Button size="mini">Mini</Button>
    <Button size="small">Small</Button>
    <Button size="medium">Medium</Button>
    <Button size="auto">Auto</Button>
    <Button size="large">Large</Button>
    <Button size="fluid">Fluid</Button>
</>
    )
}
`.trim();
  const btncode8 = `
<>
    <Button size="small" icon={<svg fill="currentColor" viewBox="0 0 20 20"><path fillRule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd"></path></svg>}>Small</Button>
    <Button size="small" iconRight={<svg fill="currentColor" viewBox="0 0 20 20"><path fillRule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd"></path></svg>}>Small</Button>
    <Button icon={<svg fill="currentColor" viewBox="0 0 20 20"><path fillRule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd"></path></svg>} >Default</Button>
    <Button size="fluid" variation="primary" icon={<svg fill="currentColor" viewBox="0 0 20 20"><path fillRule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd"></path></svg>}>Fluid</Button>
</>
`;
  const btncode9 = `
  <>
    <Button.Group>
      <Button type="button">Click Me</Button>
      <Button type="button">Hover Me</Button>
    </Button.Group>
    <Spacer x="1" d="inline-block"/>
    <Button.Group vertical>
      <Button type="button">Click Me</Button>
      <Button type="button">Hover Me</Button>
    </Button.Group>
  </>
`;
  const btncode10 = `
<>
  <Button.Link as='a' href="https://www.google.com/" target="_blank">
    Link to Google
  </Button.Link>
  <Button.Link as='a' href="tel:987875875">
    Call Me
  </Button.Link>
</>
`;
  const btncode11 = `
<>
  <IconButton>
    <Search/>
  </IconButton>
  <Spacer x="1" d="inline-block"/>
  <IconButton  circular>
    <Search/>
  </IconButton>
  <Spacer x="1" y="1"/>
  <IconButton  circular size="small">
    <Search/>
  </IconButton>
  <Spacer x="1" d="inline-block"/>
  <IconButton  circular size="mid">
  <Search/>
  </IconButton>  
  <Spacer x="1" d="inline-block"/>
  <IconButton  circular size="large">
  <Search/>
  </IconButton>
  <Spacer x="1" y="1"/>
  <IconButton variation='primary'>
  <Search/>
  </IconButton>
  <Spacer x="1" d="inline-block"/>
  <IconButton variation='success'>
  <Search/>
  </IconButton>  
  <Spacer x="1" d="inline-block"/>
  <IconButton variation='danger'>
  <Search/>
  </IconButton>  
  <Spacer x="1" d="inline-block"/>
  <IconButton variation='warning'>
  <Search/>
  </IconButton>
</>
`;
  return (
    <ComponentContentLayout>
      <Text as="h1">Button</Text>
      <Text as="p">Used to trigger an operation..</Text>
      <Spacer y={2}></Spacer>
      <Text as="h3">Basic</Text>
      <Text as="p">The basic Button contains an animation effect.</Text>
      <Playground code={btncode1} scoped={{ Button, Loading }}></Playground>
      <Spacer y={2}></Spacer>
      <Text as="h3">Loading</Text>
      <Text as="p">Show running status.</Text>
      <Playground code={btncode2} scoped={{ Button, Loading }}></Playground>
      <Spacer y={2}></Spacer>
      <Text as="h3">Disabled</Text>
      <Text as="p">Do not respond to any action.</Text>
      <Playground code={btncode3} scoped={{ Button }}></Playground>
      <Spacer y={2}></Spacer>
      <Text as="h3">Shadow</Text>
      <Text as="p">The shadow highlights the level of the button.</Text>
      <Playground code={btncode4} scoped={{ Button }}></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">Variation</Text>
      <Text as="p">Different state.</Text>
      <Playground
        className="grid"
        code={btncode5}
        scoped={{ Button }}
      ></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">GHOST</Text>
      <Text as="p">The opposite color.</Text>
      <Playground
        className="grid"
        code={btncode6}
        scoped={{ Button }}
      ></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">Sizes</Text>
      <Text as="p">Buttons of different sizes.</Text>
      <Playground code={btncode7} scoped={{ Button }}></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">With Icons</Text>
      <Text as="p">
        The color and size of the icon will be set automatically.
      </Text>
      <Playground code={btncode8} scoped={{ Button }}></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">Button Group</Text>
      <Text as="p">Groups Buttons togther.</Text>
      <Playground code={btncode9} scoped={{ Button, Spacer }}></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">Button Link</Text>
      <Text as="p">Link Button to href.</Text>
      <Playground code={btncode10} scoped={{ Button, Spacer }}></Playground>
      <Text as="h3">Icon Button</Text>
      <Text as="p">Add Icon to Button. </Text>
      <Playground
        code={btncode11}
        scoped={{ IconButton, Search, Spacer }}
      ></Playground>
    </ComponentContentLayout>
  );
};

export default ComponentButtonPage;
