import React from "react";
import ComponentContentLayout from "../../hoc/ComponentContentLayout";
import { Text, Note, Spacer, Snippet } from "suprim-ui";
import Playground from "../../global/Playground";

const ComponentSnippetPage = () => {
  const snip1 = `
<Snippet text="npm i suprim-react-ui"></Snippet>
`;
  const snip2 = `
<Snippet text="npm i suprim-react-ui" type="secondary"></Snippet>
`;
  const snip3 = `
<Snippet text="npm i suprim-react-ui" width="300"></Snippet>
`;
  const snip4 = `
  <>
<Snippet text="npm i suprim-react-ui" type="primary"></Snippet>
<Spacer y="1"></Spacer>
<Snippet text="npm i suprim-react-ui" type="success"></Snippet>
<Spacer y="1"></Spacer>
<Snippet text="npm i suprim-react-ui" type="danger"></Snippet>
<Spacer y="1"></Spacer>
<Snippet text="npm i suprim-react-ui" type="warning"></Snippet>
<Spacer y="1"></Spacer>
<Snippet text="npm i suprim-react-ui" type="secondary"></Snippet>
</>
`;
  const snip5 = `
<>
<Snippet text="npm i suprim-react-ui" type="primary" ghost></Snippet>
<Spacer y="1"></Spacer>
<Snippet text="npm i suprim-react-ui" type="success" ghost></Snippet>
<Spacer y="1"></Spacer>
<Snippet text="npm i suprim-react-ui" type="danger" ghost></Snippet>
<Spacer y="1"></Spacer>
<Snippet text="npm i suprim-react-ui" type="warning" ghost></Snippet>
<Spacer y="1"></Spacer>
<Snippet text="npm i suprim-react-ui" type="secondary" ghost></Snippet>
</>
`;
  return (
    <ComponentContentLayout>
      <Text as="h1">Snippet</Text>
      <Text as="p">
        Display a snippet of copyable code for the command line.
      </Text>
      <Spacer y="1"></Spacer>
      <Note>
        This component is a work in progress. It is not being fully used across.
      </Note>
      <Spacer y="1"></Spacer>
      <Text as="h3">Default</Text>
      <Text as="p">Basic usage.</Text>
      <Playground scoped={{ Snippet }} code={snip1}></Playground>
      <Spacer y="1"></Spacer>
      <Text as="h3">Dark</Text>
      <Playground scoped={{ Snippet }} code={snip2}></Playground>
      <Spacer y="1"></Spacer>
      <Text as="h3">Width</Text>
      <Text as="p">Specify width manually.</Text>
      <Playground scoped={{ Snippet }} code={snip3}></Playground>
      <Spacer y="1"></Spacer>
      <Text as="h3">Types</Text>
      <Text as="p">Show different states with colors.</Text>
      <Playground scoped={{ Snippet, Spacer }} code={snip4}></Playground>
      <Spacer y="1"></Spacer>
      <Text as="h3">Variants</Text>
      <Playground scoped={{ Snippet, Spacer }} code={snip5}></Playground>
    </ComponentContentLayout>
  );
};

export default ComponentSnippetPage;
