import React from "react";
import { Link, matchPath, useLocation } from "react-router-dom";
const CustomRoute = (props) => {
  const location = useLocation();
  return (
    <Link
      to={props.to}
      className={
        matchPath(location.pathname, { path: props.match }) ? "active" : ""
      }
    >
      {props.children}
    </Link>
  );
};

export default CustomRoute;
