import React from "react";
import ComponentContentLayout from "../../hoc/ComponentContentLayout";
import { Text, Spacer, Accordion } from "suprim-ui";
import Playground from "../../global/Playground";
const ComponentAccordionPage = () => {
  const acc1 = `
<Accordion>
    <Accordion.Item title="What is a dog?" >
        A dog is a type of domesticated animal. Known for its loyalty and faithfulness, it can be found as a welcome guest in many households across the world.
    </Accordion.Item>
    <Accordion.Item title="What kinds of dogs are there?">
        There are many breeds of dogs. Each breed varies in size and temperament. Owners often select a breed of dog that they find to be compatible with their own lifestyle and desires from a companion.
    </Accordion.Item>
</Accordion>
    `;
  const acc2 = `
<Accordion>
    <Accordion.Item title="What is a dog?" desc="More description about Question A" >
        A dog is a type of domesticated animal. Known for its loyalty and faithfulness, it can be found as a welcome guest in many households across the world.
    </Accordion.Item>
    <Accordion.Item title="What kinds of dogs are there?" desc="More description about Question B" >
        There are many breeds of dogs. Each breed varies in size and temperament. Owners often select a breed of dog that they find to be compatible with their own lifestyle and desires from a companion.
    </Accordion.Item>
</Accordion>
`;
  const acc3 = `
<Accordion shadow>
    <Accordion.Item title="What is a dog?" desc="More description about Question A" >
        A dog is a type of domesticated animal. Known for its loyalty and faithfulness, it can be found as a welcome guest in many households across the world.
    </Accordion.Item>
    <Accordion.Item title="What kinds of dogs are there?" desc="More description about Question B" >
        There are many breeds of dogs. Each breed varies in size and temperament. Owners often select a breed of dog that they find to be compatible with their own lifestyle and desires from a companion.
    </Accordion.Item>
</Accordion>
`;
  const acc4 = `
<Accordion padded >
    <Accordion.Item activeshadow title="What is a dog?" desc="More description about Question A" >
        A dog is a type of domesticated animal. Known for its loyalty and faithfulness, it can be found as a welcome guest in many households across the world.
    </Accordion.Item>
    <Accordion.Item activeshadow title="What kinds of dogs are there?" desc="More description about Question B" >
        There are many breeds of dogs. Each breed varies in size and temperament. Owners often select a breed of dog that they find to be compatible with their own lifestyle and desires from a companion.
    </Accordion.Item>
</Accordion>
`;
  const acc5 = `
<Accordion shadow multiple >
    <Accordion.Item title="What is a dog?" desc="More description about Question A" >
        A dog is a type of domesticated animal. Known for its loyalty and faithfulness, it can be found as a welcome guest in many households across the world.
    </Accordion.Item>
    <Accordion.Item title="What kinds of dogs are there?" desc="More description about Question B" >
        There are many breeds of dogs. Each breed varies in size and temperament. Owners often select a breed of dog that they find to be compatible with their own lifestyle and desires from a companion.
    </Accordion.Item>
</Accordion>
`;
  return (
    <ComponentContentLayout>
      <Text as="h1">Accordion</Text>
      <Text as="p">
        An accordion allows users to toggle the display of sections of content.
      </Text>
      <Spacer y="2"></Spacer>
      <Text as="h3">Default</Text>
      <Text as="p">Show title only by default.</Text>
      <Playground code={acc1} scoped={{ Accordion }}></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">Subtitle</Text>
      <Text as="p">Add text or components as subtitle.</Text>
      <Playground code={acc2} scoped={{ Accordion }}></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">Shadow</Text>
      <Text as="p">Highlight importance at a higher level.</Text>
      <Playground code={acc3} scoped={{ Accordion }}></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">Padded</Text>
      <Text as="p">Highlight importance at a higher level.</Text>
      <Playground code={acc4} scoped={{ Accordion }}></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">Multiple</Text>
      <Text as="p">Highlight importance at a higher level.</Text>
      <Playground code={acc5} scoped={{ Accordion }}></Playground>
    </ComponentContentLayout>
  );
};

export default ComponentAccordionPage;
