import React from "react";
import ComponentContentLayout from "../../hoc/ComponentContentLayout";
import { Card, Text, Spacer } from "suprim-ui";
import Playground from "../../global/Playground";
const ComponentCardPage = () => {
  const card1 = `
    <Card><Card.Content>A Basic Card</Card.Content></Card>
    `;
  const card2 = `
    <Card hoverable><Card.Content>A Basic Hoverable Card</Card.Content></Card>
    `;
  const card3 = `
<Card shadow>
    <Card.Content>
        <Text as="h4"> A Basic Shadow Card</Text>
        <Text as="p">shadow card.</Text>
    </Card.Content>
</Card>
    `;
  const card4 = `
<Card shadow>
    <Card.Content>
        <Card.Title> A Basic Card Title</Card.Title>
        <Card.Desc>About card desc.</Card.Desc>
    </Card.Content>
</Card>
    `;
  const card5 = `
<Card >
    <Card.Content>
        <Card.Title> A Basic Card Title</Card.Title>
        <Card.Desc>About card desc.</Card.Desc>
    </Card.Content>
    <Card.Footer>
        Footer
    </Card.Footer>
</Card>
    `;
  const card6 = `
<Card shadow>
    <Card.Image >
        <img src="https://images.pexels.com/photos/318391/pexels-photo-318391.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" alt="card-alt"></img>
    </Card.Image>
    <Card.Content>
        <Card.Title>
            <a href="/">Suprim React UI</a>
        </Card.Title>
        <Card.Desc>Modern and minimalist React UI library.</Card.Desc>
    </Card.Content>
    <Card.Footer className="js-between">
        <Text as="p">Footer</Text>
    </Card.Footer>
</Card>
    `;
  const card7 = `
<Card w="400px" shadow>
    <Card.Image >
        <img src="https://images.pexels.com/photos/318391/pexels-photo-318391.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" alt="card-alt"></img>
    </Card.Image>
    <Card.Content>
        <Card.Title>
            <a href="/">Suprim React UI</a>
        </Card.Title>
        <Card.Desc>Modern and minimalist React UI library.</Card.Desc>
    </Card.Content>
    <Card.Footer className="js-between">
        <Text as="p">Footer</Text>
    </Card.Footer>
</Card>
`;
  return (
    <ComponentContentLayout>
      <Text as="h1">Card</Text>
      <Text as="p">
        A card displays site content in a manner similar to a playing card.
      </Text>
      <Spacer y={2}></Spacer>
      <Text as="h3">Basic</Text>
      <Text as="p">The basic Card.</Text>
      <Playground code={card1} scoped={{ Card }}></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">Hoverable</Text>
      <Text as="p">The basic Hoverable Card.</Text>
      <Playground code={card2} scoped={{ Card }}></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">Shadow</Text>
      <Text as="p">The basic Shadow Card.</Text>
      <Playground code={card3} scoped={{ Card, Text }}></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">Title & Desc</Text>
      <Playground code={card4} scoped={{ Card }}></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">With Footer</Text>
      <Playground code={card5} scoped={{ Card }}></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">Image</Text>
      <Playground code={card6} scoped={{ Card, Text }}></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">Width</Text>
      <Playground code={card7} scoped={{ Card, Text }}></Playground>
    </ComponentContentLayout>
  );
};

export default ComponentCardPage;
