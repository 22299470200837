import React, { useEffect } from 'react'
import ComponentContentLayout from '../../hoc/ComponentContentLayout'
import { useHistory } from 'react-router-dom';

const ComponentsPage = () => {
    const history = useHistory();

    useEffect(() => {
        history.push('/components/text');
    }, [history]);
    return (
        <ComponentContentLayout>
            Components
        </ComponentContentLayout>
    )
}

export default ComponentsPage
