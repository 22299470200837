import React from "react";
import MainLayout from "./MainLayout";
import { Container, Grid } from "suprim-ui";
import { NavLink } from "react-router-dom";
const ComponentContentLayout = ({ children }) => {
  return (
    <MainLayout>
      <section className="site-app">
        <Container>
          <Grid>
            <Grid.Item lg={2} className="component-sidebar">
              <section className="site-sidebar">
                <h3 className="site-sidebar--title">General</h3>
                <ul className="site-sidebar--lists">
                  <li>
                    <NavLink to="/components/text">Text</NavLink>
                  </li>
                  <li>
                    <NavLink to="/components/img">Img</NavLink>
                  </li>

                  <li>
                    <NavLink to="/components/button">Button</NavLink>
                  </li>
                  <li>
                    <NavLink to="/components/code">Code</NavLink>
                  </li>
                  <li>
                    <NavLink to="/components/icons">Icons</NavLink>
                  </li>
                </ul>
                <h3 className="site-sidebar--title">DATA ENTRY</h3>
                <ul className="site-sidebar--lists">
                  <li>
                    <NavLink to="/components/input">Input</NavLink>
                  </li>
                </ul>
                <h3 className="site-sidebar--title">NAVIGATION</h3>
                <ul className="site-sidebar--lists">
                  <li>
                    <NavLink to="/components/navbar">NavBar</NavLink>
                  </li>
                  <li>
                    <NavLink to="/components/dropdown">DropDown</NavLink>
                  </li>
                  <li>
                    <NavLink to="/components/drawer">Drawer</NavLink>
                  </li>
                </ul>
                <h3 className="site-sidebar--title">LAYOUT</h3>
                <ul className="site-sidebar--lists">
                  <li>
                    <NavLink to="/components/container">Container</NavLink>
                  </li>
                  <li>
                    <NavLink to="/components/grid">Grid</NavLink>
                  </li>
                  <li>
                    <NavLink to="/components/spacer">Spacer</NavLink>
                  </li>
                  <li>
                    <NavLink to="/components/divider">Divider</NavLink>
                  </li>
                </ul>
                <h3 className="site-sidebar--title">SURFACES</h3>
                <ul className="site-sidebar--lists">
                  <li>
                    <NavLink to="/components/card">Card</NavLink>
                  </li>
                  <li>
                    <NavLink to="/components/box">Box</NavLink>
                  </li>
                </ul>
                <h3 className="site-sidebar--title">MODULES</h3>
                <ul className="site-sidebar--lists">
                  <li>
                    <NavLink to="/components/accordion">Accordion</NavLink>
                  </li>
                  <li>
                    <NavLink to="/components/tabs">Tabs</NavLink>
                  </li>
                </ul>
                <h3 className="site-sidebar--title">FEEDBACK</h3>
                <ul className="site-sidebar--lists">
                  <li>
                    <NavLink to="/components/spinner">Spinner</NavLink>
                  </li>
                  <li>
                    <NavLink to="/components/progress">Progress</NavLink>
                  </li>
                  <li>
                    <NavLink to="/components/note">Note</NavLink>
                  </li>
                  <li>
                    <NavLink to="/components/toast">Toast</NavLink>
                  </li>
                  <li>
                    <NavLink to="/components/modal">Modal</NavLink>
                  </li>
                  <li>
                    <NavLink to="/components/snippet">Snippet</NavLink>
                  </li>
                </ul>
                <h3 className="site-sidebar--title">DATA DISPLAY</h3>
                <ul className="site-sidebar--lists">
                  <li>
                    <NavLink to="/components/avatar">Avatar</NavLink>
                  </li>
                  <li>
                    <NavLink to="/components/tooltip">Tooltip</NavLink>
                  </li>
                  <li>
                    <NavLink to="/components/user">User</NavLink>
                  </li>
                  <li>
                    <NavLink to="/components/tag">Tag</NavLink>
                  </li>
                  <li>
                    <NavLink to="/components/dot">Dot</NavLink>
                  </li>
                </ul>
                <h3 className="site-sidebar--title">UTILS & Hooks</h3>
                <ul className="site-sidebar--lists">
                  <li>
                    <NavLink to="/components/scrollrestoration">
                      Scroll Restoration
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/components/uselockbodyscroll">
                      UseLockBodyScroll
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/components/usecopyclipBoard">
                      UseCopyClipBoard
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/components/useclickaway">
                      UseClickAway
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/components/usesticky">UseSticky</NavLink>
                  </li>
                  <li>
                    <NavLink to="/components/usetoggle">UseToggle</NavLink>
                  </li>
                  <li>
                    <NavLink to="/components/usewindowresize">
                      UseWindowResize
                    </NavLink>
                  </li>
                </ul>
              </section>
            </Grid.Item>
            <Grid.Item lg={8}>
              <section className="site-main">{children}</section>
            </Grid.Item>
          </Grid>
        </Container>
      </section>
    </MainLayout>
  );
};

export default ComponentContentLayout;
