import React from "react";
import ComponentContentLayout from "../../hoc/ComponentContentLayout";
import { AlertCircle } from "suprim-react-icons";
import { Text, Box, Spacer } from "suprim-ui";
import Playground from "../../global/Playground";

const ComponentBoxPage = () => {
  const box1 = `
<Box
d="flex"
alignItems="center"
justifyContent="center"
padding={{
  p:"1rem"
}}
bg="black"
color="#fff"
borderColor="#fff"
borderRadius="10px"
h="200px"
shadow
>
  <AlertCircle />
  <Spacer x="1"></Spacer>
  This is a simple box.
</Box>
  `;
  return (
    <ComponentContentLayout>
      <Text as="h1">Box</Text>
      <Text as="h3">Default</Text>
      <Playground
        code={box1}
        scoped={{ Box, AlertCircle, Spacer }}
      ></Playground>
    </ComponentContentLayout>
  );
};

export default ComponentBoxPage;
