import React from "react";
import { Box, Spacer, Text } from "suprim-ui";
import ComponentContentLayout from "../../hoc/ComponentContentLayout";

const UseWindowResizePage = () => {
  const code = `
  import { useState, useEffect } from 'react'

  const getWidth = () =>
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth
  
  function useWindowResize() {
    const [width, setWidth] = useState(getWidth())
    useEffect(() => {
      let timeoutId = null
      const resizeListener = () => {
        clearTimeout(timeoutId)
        timeoutId = setTimeout(() => setWidth(getWidth()), 150)
      }
      window.addEventListener('resize', resizeListener)
  
      return () => {
        window.removeEventListener('resize', resizeListener)
      }
    }, [])
  
    return width
  }
  
  export default useWindowResize
  `;
  return (
    <ComponentContentLayout>
      <Text as="h1">useWindowResize</Text>
      <Text as="p">Handle window Resize.</Text>
      <Spacer y="2"></Spacer>
      <Box p="1rem" bg="#f1f1f1" css={{ fontSize: "1rem" }}>
        <pre>{code}</pre>
      </Box>
    </ComponentContentLayout>
  );
};

export default UseWindowResizePage;
