import React from "react";
import ComponentContentLayout from "../../hoc/ComponentContentLayout";
import { Text, Progress, Spacer } from "suprim-ui";
import Playground from "../../global/Playground";
const ComponentProgressPage = () => {
  const prog1 = `
    <Progress value="60" ></Progress>
    `;
  const prog2 = `
    <Progress value="45" max="50" ></Progress>
    `;
  const prog3 = `
    <Progress value="45" max="50" color="red"></Progress>
    `;
  const prog4 = `
<>
    <Progress value="45" max="50" type="primary"></Progress>
    <Spacer y="1"></Spacer>
    <Progress value="5" max="50" type="success"></Progress>
    <Spacer y="1"></Spacer>
    <Progress value="25" max="50" type="danger"></Progress>
    <Spacer y="1"></Spacer>
    <Progress value="50" max="50" type="warning"></Progress>
    <Spacer y="1"></Spacer>
    <Progress value="10" max="50" type="secondary"></Progress>
</>
    `;
  return (
    <ComponentContentLayout>
      <Text as="h1">Progress</Text>
      <Text as="p">
        Display progress relative to a limit or related to a task.
      </Text>
      <Spacer y="2"></Spacer>
      <Text as="h3">Default</Text>
      <Playground code={prog1} scoped={{ Progress }}></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">Custom Max</Text>
      <Text as="p">
        You can specify the color of the progress in any range.
      </Text>
      <Playground code={prog2} scoped={{ Progress }}></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">Dynamic Colors</Text>
      <Text as="p">
        You can specify the color of the progress in any range.
      </Text>
      <Playground code={prog3} scoped={{ Progress }}></Playground>
      <Spacer y="2"></Spacer>
      <Text as="h3">Type</Text>
      <Text as="p">We prepared some different styles in advance.</Text>
      <Playground code={prog4} scoped={{ Progress, Spacer }}></Playground>
    </ComponentContentLayout>
  );
};

export default ComponentProgressPage;
